
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../../api";

export const sendCode = createAsyncThunk(
  'identity/sendCode',
  async (id: string, thunk) => {
    await api(thunk.dispatch).auth.sendCode(id);
  }
)


