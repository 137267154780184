
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumPartsByLength from "../sum/partsByLength";
import {isOnHold} from "../filters/hold";


export type BreakdownByLengthProps = {
  part: string
}

export function BreakdownByLength(props: BreakdownByLengthProps) {
  const {part} = props;

  return <Breakdown
    title={`${props.part}`}
    description={ `On Hold, Length (meters)` }
    filter={(quotes) => quotes.filter(isOnHold)}
    sum={(items) => sumPartsByLength(part, items).toString()}
  />
}

export default BreakdownByLength;
