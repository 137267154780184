
import {h} from "preact";
import Breakdown from "../lib/Breakdown";
import salesBySum from "../sum/salesBySum";
import {isOnHold} from "../filters/hold";
import SumType, {typeToLabel} from "../enums/sum-type";


export type BreakdownByLengthProps = {
  type: SumType
}

export function SalesHold(props: BreakdownByLengthProps) {
  const {type} = props;

  return <Breakdown
    title={`Sales - ${typeToLabel(type)}`}
    description={ `On Hold, Value (£)` }
    filter={(quotes) => quotes.filter(isOnHold).filter(q => type !== "paint" || q.painted)}
    sum={(items) => salesBySum(type, items).toString()}
  />
}

export default SalesHold;
