import { h } from 'preact';
import { Route, Router } from 'preact-router';
import { Provider } from 'react-redux'
import store from './store'

import Header from './components/header';

// Code-splitting is automated for `routes` directory
import QuotePageList from "./routes/quotes/list";
import QuoteItemPage from "./routes/quotes/item";
import ErrorBoundary from "./components/error/ErrorBoundary";
import {Login} from "./feature/identity";
import MaterialSummary from "./feature/materials/component/Summary";
import BreakdownByWeight from "./feature/materials/component/BreakdownByWeight";
import BreakdownByLength from "./feature/materials/component/BreakdownByLength";
import BreakdownByQuantity from "./feature/materials/component/BreakdownByQuantity";
import BreakdownByThickness from "./feature/materials/component/BreakdownByThickness";
import UndatedByThickness from "./feature/materials/component/UndatedByThickness";
import UndatedByWeight from "./feature/materials/component/UndatedByWeight";
import UndatedByLength from "./feature/materials/component/UndatedByLength";
import UndatedByQuantity from "./feature/materials/component/UndatedByQuantity";
import OverrunByLength from "./feature/materials/component/OverrunByLength";
import OverrunByQuantity from "./feature/materials/component/OverrunByQuantity";
import OverrunByWeight from "./feature/materials/component/OverrunByWeight";
import OverrunByThickness from "./feature/materials/component/OverrunByThickness";
import HoldByLength from "./feature/materials/component/HoldByLength";
import HoldByQuantity from "./feature/materials/component/HoldByQuantity";
import HoldByWeight from "./feature/materials/component/HoldByWeight";
import HoldByThickness from "./feature/materials/component/HoldByThickness";
import SalesSummary from "./feature/sales/component/Summary";
import SalesHold from "./feature/sales/component/Hold";
import SalesOverrun from "./feature/sales/component/Overrun";
import SalesUndated from "./feature/sales/component/Undated";
import SalesBreakdown from "./feature/sales/component/Breakdown";


const App = () => (

	<Provider store={store}>
		<div id="app">
		<ErrorBoundary>
			<Login>
					<Header />
					<Router>
						<Route path="/" component={QuotePageList} />
						<Route path="/materials" component={MaterialSummary} />
						<Route path="/materials/thickness/:thickness" component={BreakdownByThickness} />
						<Route path="/materials/weight/:part" component={BreakdownByWeight} />
						<Route path="/materials/length/:part" component={BreakdownByLength} />
						<Route path="/materials/quantity/:part" component={BreakdownByQuantity} />
						<Route path="/materials/hold/thickness/:thickness" component={HoldByThickness} />
						<Route path="/materials/hold/weight/:part" component={HoldByWeight} />
						<Route path="/materials/hold/length/:part" component={HoldByLength} />
						<Route path="/materials/hold/quantity/:part" component={HoldByQuantity} />
						<Route path="/materials/overrun/thickness/:thickness" component={OverrunByThickness} />
						<Route path="/materials/overrun/weight/:part" component={OverrunByWeight} />
						<Route path="/materials/overrun/length/:part" component={OverrunByLength} />
						<Route path="/materials/overrun/quantity/:part" component={OverrunByQuantity} />
						<Route path="/materials/undated/thickness/:thickness" component={UndatedByThickness} />
						<Route path="/materials/undated/weight/:part" component={UndatedByWeight} />
						<Route path="/materials/undated/length/:part" component={UndatedByLength} />
						<Route path="/materials/undated/quantity/:part" component={UndatedByQuantity} />
						<Route path="/sales" component={SalesSummary} />
						<Route path="/sales/:type" component={SalesBreakdown} />
						<Route path="/sales/hold/:type" component={SalesHold} />
						<Route path="/sales/overrun/:type" component={SalesOverrun} />
						<Route path="/sales/undated/:type" component={SalesUndated} />
						<Route path="/:id" component={QuoteItemPage} />
					</Router>
			</Login>
		</ErrorBoundary>
	</div>
	</Provider>
);

export default App;
