
import {HandlerCreator} from "../Handler";
import {Dispatch} from "@reduxjs/toolkit";
import unauthorised from "../actions/Unauthorised";

export const AuthorisationHandler: HandlerCreator =
  (dispatch: Dispatch) => (response: Response) => {
    if (response.status == 401) {
      dispatch(unauthorised());
    }
  };

export default AuthorisationHandler;
