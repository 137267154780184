
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../../api";
import {RootState} from "../../../store";

export const fetchAll = createAsyncThunk(
  'quote/fetchAll',
  async (a, thunk) => {
    const state = thunk.getState() as RootState;
    return api(thunk.dispatch).quote.fetchAll([state.identity.id ?? "", state.identity.code ?? ""]);
  }
)
