
import {ComponentChildren, Fragment, h} from "preact";
import classNames from "classnames";
import {IoCheckmark, IoClose} from "react-icons/io5";
import {Loader} from "./Loader";
import style from "./Stage.module.css"


export type StageProps = {
  class?: classNames.Argument,
  children?: ComponentChildren,
  value: string,
  pending?: boolean,
  loading?: boolean,
  onCommit?: (value: any) => any
  onRevert?: (value: any) => any
}


export const Stage = (props: StageProps) => {
  const {
    value,
    children,
    pending = false,
    loading = false,
    onRevert = () => null,
    onCommit = () => null,
  } = props;

  if (loading) return <Loader />;
  if (!pending) return null;

  return <div class={style.ctrl}>
    <button style={{ padding: "0.2em 0.6em" }} onClick={() => onRevert(value)}><IoClose size={16} /></button>
    <button style={{ padding: "0.2em 0.6em" }} onClick={() => onCommit(value)}><IoCheckmark size={16}  /></button>
  </div>
}

export default Stage;
