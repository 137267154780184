
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../../api";
import {RootState} from "../../../store";

export const fetchById = createAsyncThunk(
  'quote/fetchById',
  async (id: string, thunk) => {
    const state = thunk.getState() as RootState;
    return api(thunk.dispatch).quote.fetchById([state.identity.id ?? "", state.identity.code ?? ""], id);
  }
)
