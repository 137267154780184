
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../../api";
import {RootState} from "../../../store";
import {url} from "../../../api/common";

export const share = createAsyncThunk(
  'file/share',
  async (p: { qid: string, fid: string }, thunk) => {
    const state = thunk.getState() as RootState;
    const share = await api(thunk.dispatch).file.share([state.identity.id ?? "", state.identity.code ?? ""], p.qid, p.fid);
    window.open(`${url}/download/${share.id}`);
  }
)
