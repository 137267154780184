import {BaseItem, withLength} from "../../file/File";

function sumPartsByLength(part: string, items: BaseItem[]) {
  const x = Math.round(
    withLength(items)
      .filter(i => i.part === part)
      .reduce((acc, cur) => acc += cur.length * cur.quantity, 0)
  );
  return x != 0 ? x.toLocaleString('en-gb') : "-";
}

export default sumPartsByLength;
