
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumPartsByWeight from "../sum/sumPartsByWeight";


export type OverrunByWeightProps = {
  part: string
}

export function UndatedByWeight(props: OverrunByWeightProps) {
  const {part} = props;

  return <Breakdown
    title={`${part}`}
    description={ "Undated, Weight (tonnes)" }
    filter={(quotes) => quotes.filter(q => q.start == null)}
    sum={(items) => sumPartsByWeight(part, items).toString()}
  />
}

export default UndatedByWeight;
