
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumPartsByWeight from "../sum/sumPartsByWeight";
import {isOverrun} from "../filters/overrun";


export type OverrunByWeightProps = {
  part: string
}

export function UndatedByWeight(props: OverrunByWeightProps) {
  const {part} = props;

  return <Breakdown
    title={`${part}`}
    description={ "Overrun, Weight (tonnes)" }
    filter={(quotes) => quotes.filter(isOverrun)}
    sum={(items) => sumPartsByWeight(part, items).toString()}
  />
}

export default UndatedByWeight;
