
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumThicknessByWeight from "../sum/thicknessByWeight";
import {isOnHold} from "../filters/hold";


export type BreakdownByThicknessProps = {
  thickness: string
}

export function BreakdownByThickness(props: BreakdownByThicknessProps) {
  const thickness = parseFloat(props.thickness);

  return <Breakdown
    title={`${thickness}mm Sheet Material`}
    description={ "On Hold, Weight (tonnes)" }
    filter={(quotes) => quotes.filter(isOnHold)}
    sum={(items) => sumThicknessByWeight(thickness, items).toString() }
  />
}

export default BreakdownByThickness;
