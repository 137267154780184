
import { h } from 'preact';
import QuoteEdit from "../../feature/quote/component/QuoteEdit";


type QuoteItemPageProps = {
  id: string
}


const QuoteItemPage = ({id}: QuoteItemPageProps) => {
  return <QuoteEdit id={id} />
};

export default QuoteItemPage;
