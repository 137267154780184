
import {h} from "preact";
import style from "./Summary.module.css";
import {BaseItem, withThickness, withWeight} from "../../file/File";
import classNames from "classnames";
import {Link} from "preact-router/match";
import sumThicknessByWeight from "../sum/thicknessByWeight";


export type ThicknessByWeightProps = {
  thickness: number,
  interval: string,
  duration: number,
  dates: [number, number][],
  options?: string
  items: BaseItem[][],
  tabs: ({
    key: string,
    items: BaseItem[]
  } | null)[]
}

export const ThicknessByWeight = (props: ThicknessByWeightProps) => {
  const {thickness, items, interval, duration, tabs=[], dates, options=""} = props;

  return <tr>
    <th class={classNames([style.name])}>{thickness}mm</th>
    {items.map((i, k) => <td key={k} class={classNames([style.number])}>
      <Link href={`/materials/thickness/${thickness}/?start=${dates[k][0]}&interval=${interval}&duration=1${options}`}>
        { sumThicknessByWeight(thickness, i) }
      </Link>
    </td>)}
    <td class={classNames([style.number])}>
      <Link href={`/materials/thickness/${thickness}/?start=${dates[0][0]}&interval=${interval}&duration=${duration}${options}`}>
        { sumThicknessByWeight(thickness, items.reduce((acc, cur) => [...acc, ...cur])) }
      </Link>
    </td>
    {tabs.map(k => {
        if (k == null) return null;
        return <td key={k} className={classNames([style.number, style.tab])}>
          <Link
            href={`/materials/${k.key}/thickness/${thickness}/?start=${dates[0][0]}&interval=${interval}&duration=${duration}`}>
            {sumThicknessByWeight(thickness, k.items)}
          </Link>
        </td>
      }
    )}
  </tr>
}
