
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../../api";
import {fetchAll} from "./fetchAll";
import {RootState} from "../../../store";
import { route } from 'preact-router';

export const deleteById = createAsyncThunk(
  'quote/create',
  async (id: string, thunk) => {
    const state = thunk.getState() as RootState;
    await api(thunk.dispatch).quote.deleteById([state.identity.id ?? "", state.identity.code ?? ""], id);
  }
)

export const deleteByIdAndFetch = (id: string) => {
  return async (dispatch) => {
    await dispatch(deleteById(id));
    return dispatch(fetchAll());
  }
}

export const deleteByIdAndGotoList = (id: string) => {
  return async (dispatch) => {
    await dispatch(deleteById(id));
    await dispatch(fetchAll());
    route(`/`);
  }
}
