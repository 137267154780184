
import {Handler, HandlerCreator} from "../Handler";
import {Dispatch} from "@reduxjs/toolkit";
import Authorisation from "./Authorisation";

export const RootHandler: HandlerCreator =
  (dispatch: Dispatch) => {
    const handlers: Handler[] = [
      Authorisation(dispatch),
    ];

    return (response: Response) => {
      handlers.map(handler => handler(response));
    }
  };

export default RootHandler;
