
import { h, ComponentChildren } from 'preact';
import {IconType} from "react-icons";
import style from './style.css';
import Panel from "../panel";
import * as Input from "../input";
import {useState} from "react";


type ViewProps = {
	ctrl?: ComponentChildren[][],
	children?: ComponentChildren
}

const View = ({children, ctrl = []}: ViewProps) => {
	let i = 0;
	return <div class={style.view}>
		<div class={style.ctrl}>
			{ctrl.map(actions => {
				return <div key={i++} class={style.ctrlGroup}>
					{actions}
				</div>
			})}
		</div>
		<div class={style.vp}>
			<main class={style.main}>
				{children}
			</main>
		</div>
	</div>;
}

export default View;
