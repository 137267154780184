import { h } from 'preact';
import style from './style.css';

const Placeholder = (props) => (
	<div class={style.placeholder}>
		{props.children}
	</div>
);

export default Placeholder;
