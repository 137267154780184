
import {Dispatch} from "@reduxjs/toolkit";
import Root from "./handlers/Root";
import {AuthPlugin} from "./calls/auth";
import FilePlugin from "./calls/file";
import QuotePlugin from "./calls/quote";


export function init(dispatch: Dispatch) {
  const root = Root(dispatch);

  return {
    auth: AuthPlugin(root),
    file: FilePlugin(root),
    quote: QuotePlugin(root),
  }
}

export default init;
