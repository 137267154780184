
import { h } from 'preact';
import style from "./quote.module.css";
import {useEffect, useState} from "react";
import {Link} from "preact-router/match";
import {useAppDispatch, useAppSelector} from "../../../hooks";

import {
  deleteByIdAndGotoList,
  editProperty,
  fetchAll,
  resetEdit, saveById,
  selectEditQuote,
  selectQuote,
  selectQuoteList,
  setPropertyById
} from "../QuoteSlice";

import {
  Button,
  Checkbox,
  File,
  Group,
  List,
  Input,
  Select,
  Set,
  Text,
  Stage
} from "../../../components/input";

import { uploadAndRefresh } from "../../file/action/upload";

import Placeholder from "../../../components/placeholder";
import View from "../../../components/view";
import FileItem from "../../file/component/FileItem";
import UploadItem from "../../file/component/UploadItem";

import {LuArchive, LuArrowLeft, LuDelete, LuRepeat, LuSave, LuStepBack, LuTrash} from "react-icons/lu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FileList from "../../file/component/FileList";
import {TiCancel} from "react-icons/ti";
import {FcCancel} from "react-icons/fc";



type QuoteProps = {
  id: string
}


export const QuoteEdit = (props: QuoteProps) => {
  const {id} = props;

  const dispatch = useAppDispatch();
  const state = useAppSelector(selectQuoteList);
  const q = useAppSelector(selectQuote(id));
  const e = useAppSelector(selectEditQuote);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    dispatch(fetchAll());
    dispatch(resetEdit(id));
  }, [id]);

  useEffect(() => {
    if (e == null) return;
    setPending(
      e.name.value !== null
      || e.client.value !== null
      || e.postCode.value !== null
      || e.issued.value !== null
      || e.start.value !== null
      || e.status.value !== null
      || e.painted.value !== null
    );
  }, [e])

  if (state.loading) return <Placeholder>Loading</Placeholder>;

  if (q == null) return <Placeholder>Error: quote "${id}" not found</Placeholder>;
  if (e == null) return <Placeholder>Error: quote "${id}" not editable</Placeholder>;

  return <View
      ctrl={[
        [
          <Button key="save" disabled={!pending} label="Save" icon={<LuSave />} onClick={() => dispatch(saveById())} />,
          <Button key="refresh" label="Refresh" icon={<LuRepeat />} onClick={() => dispatch(fetchAll())} />,
          <Button key="reset" disabled={!pending} danger label="Reset" icon={<LuDelete />} onClick={() => dispatch(resetEdit(id))} />,
        ],[
          <Button key="delete" danger label="Delete" icon={<LuTrash />} onClick={() => dispatch(deleteByIdAndGotoList(id))} />
        ]
      ]}
    >
    <div class={style.quote}>
      <div style={{ paddingTop: "1em" }}>
        <Link href={`/`}><LuArrowLeft /> Back to List</Link>
      </div>
      <Group>
        <Set>
          <Input
            label="Status"
            error={e.start.error}>
            <Select
              value={e.status.value == null ? q.status : e.start.value}
              onChange={(v) => dispatch(editProperty({ status: v ?? "quoting" }))}
            >
              <option value={"Quote"}>Quote</option>
              <option value={"Order"}>Order</option>
              <option value={"Hold"}>Hold</option>
              <option value={"Complete"}>Complete</option>
              <option value={"Cancelled"}>Cancelled</option>
            </Select>
            <Stage
              value={e.status.value === null ? q.status : e.status.value}
              pending={e.status.value !== null && e.status.value !== q.status}
              onCommit={(v) => dispatch(setPropertyById({id, props: { status: v}}))}
              onRevert={(v) => dispatch(editProperty({ status: null }))}
            />
          </Input>
          <Input
            label="Start"
            error={e.start.error}>
            <DatePicker
              selected={e.start.value === null ? q.start : e.start.value}
              dateFormat="dd/MM/yyyy"
              onChange={(v) => dispatch(editProperty({ start: v == null ? "" : v.getTime()}))}
            />
            <Stage
              value={e.start.value === null ? q.start : e.start.value}
              pending={e.start.value !== null && e.start.value !== q.start}
              onCommit={(v) => dispatch(setPropertyById({id, props: { start: v}}))}
              onRevert={(v) => dispatch(editProperty({ start: null }))}
            />
          </Input>
        </Set>
        <Set>
          <Input
            label="Project"
            error={e.name.error}>
            <Text
              minLength={42}
              error={e.name.error != null}
              value={e.name.value === null ? q.name : e.name.value}
              onChange={(v) => dispatch(editProperty({ name: v ?? "" }))}
            />
            <Stage
              value={e.name.value === null ? q.name : e.name.value}
              pending={e.name.value !== null && e.name.value !== q.name}
              onCommit={(v) => dispatch(setPropertyById({id, props: { name: v }}))}
              onRevert={(v) => dispatch(editProperty({ name: null }))}
            />
          </Input>
        </Set>
        <Set>
          <Input
            label="Client"
            error={e.client.error}>
            <Text
              error={e.client.error != null}
              value={e.client.value === null ? q.client : e.client.value}
              onChange={(v) => dispatch(editProperty({ client: v ?? "" }))}
            />
            <Stage
              value={e.client.value === null ? q.client : e.client.value}
              pending={e.client.value !== null && e.client.value !== q.client}
              onCommit={(v) => dispatch(setPropertyById({id, props: { client: v }}))}
              onRevert={(v) => dispatch(editProperty({ client: null }))}
            />
          </Input>
          <Input
            label="Post Code"
            error={e.postCode.error}>
            <Text
              maxLength={10}
              error={e.postCode.error != null}
              value={e.postCode.value === null ? q.postCode : e.postCode.value}
              onChange={(v) => dispatch(editProperty({ postCode: v ?? "" }))}
            />
            <Stage
              value={e.postCode.value === null ? q.postCode : e.postCode.value}
              pending={e.postCode.value !== null && e.postCode.value !== q.postCode}
              onCommit={(v) => dispatch(setPropertyById({id, props: { postCode: v}}))}
              onRevert={(v) => dispatch(editProperty({ postCode: null }))}
            />
          </Input>
        </Set>
        <List>
          <Set>
            <Checkbox
              label="Painted"
              value={e.painted.value !== null ? e.painted.value : q.painted}
              onChange={(v) => dispatch(editProperty({ painted: v}))}
            />
            <Stage
              value={e.painted.value === null ? q.painted : e.painted.value}
              pending={e.painted.value !== null && e.painted.value !== q.painted}
              onCommit={(v) => dispatch(setPropertyById({id, props: { painted: v}}))}
              onRevert={(v) => dispatch(editProperty({ painted: null }))}
            />
          </Set>
        </List>
      </Group>
      <Group heading="Files">
        <FileList qid={id} />
        { e.uploads.map(u => <UploadItem key={u.id} id={u.id} files={u.files} loading={u.loading} error={u.error} /> ) }
        <File onUpload={(f) => dispatch(uploadAndRefresh({id, files: f}))} />
      </Group>
      <p style={{ fontSize: "0.8em" }}>
        <div>created {(new Date(q.created)).toLocaleString()}</div>
        <div>modified {(new Date(q.modified)).toLocaleString()}</div>
      </p>
    </div>
  </View>
}

/*
  <Input
    label="Created"
    error={e.issued.error}>
    <span>2023 / 02 / 05</span>
  </Input>
 */

export default QuoteEdit;


