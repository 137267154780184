
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumPartsByLength from "../sum/partsByLength";
import useDateRange from "../hooks/useDateRange";
import {formatDateRange} from "../utils/dateRange";


export type BreakdownByLengthProps = {
  part: string
}

export function BreakdownByLength(props: BreakdownByLengthProps) {
  const {part} = props;

  return <Breakdown
    title={`${props.part}`}
    description={ "Undated, Length (metres)" }
    filter={(quotes) => quotes.filter(q => q.start == null)}
    sum={(items) => sumPartsByLength(part, items).toString()}
  />
}

export default BreakdownByLength;
