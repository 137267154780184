
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type { RootState } from '../../store'

// Actions
import {create, createAndFetch, createAndOpen} from "./action/create";
import {fetchAll} from "./action/fetchAll";
import {fetchById} from "./action/fetchById";
import {deleteById, deleteByIdAndFetch, deleteByIdAndGotoList} from "./action/deleteById";
import {setPropertyById, saveById} from "./action/modify";


type Status = "InProgress" | "OnHold" | "Accepted" | "Cancelled";

type Quote = {
  id: string,
  name: string,
  client?: string|null,
  postCode?: string|null,
  issued?: number|null,
  start?: number|null,
  status: Status,
}

type TextInputState = {
  value?: string|null,
  error?: string|null,
  loading: boolean
}

function textInput(): TextInputState {
  return {
    value: null,
    error: null,
    loading: false
  }
}

type DateInputState = {
  value?: number|null,
  error?: string|null,
  loading: boolean
}

function dateInput(): DateInputState {
  return {
    value: null,
    error: null,
    loading: false
  }
}

type SelectInputState = {
  value?: any|null,
  error?: string|null,
  loading: boolean
}

function selectInput(): SelectInputState {
  return {
    value: null,
    error: null,
    loading: false
  }
}

type CheckboxInputState = {
  value?: boolean|null,
  error?: string|null,
  loading: boolean
}

function checkboxInput(): CheckboxInputState {
  return {
    value: null,
    error: null,
    loading: false
  }
}

type Upload = {
  id: string,
  files: string[],
  loading: boolean,
  error?: string|null,
}

type QuoteEdit = {
  id: string,
  name: TextInputState,
  client: TextInputState,
  postCode: TextInputState,
  issued: DateInputState,
  start: DateInputState,
  status: SelectInputState,
  painted: CheckboxInputState,
  uploads: Upload[]
}

function quoteEdit(id: string): QuoteEdit {
  return {
    id,
    name: textInput(),
    client: textInput(),
    postCode: textInput(),
    issued: dateInput(),
    start: dateInput(),
    status: selectInput(),
    painted: checkboxInput(),
    uploads: [],
  }
}

// Define a type for the slice state
type QuoteSlice = {
  index: any[],
  edit: QuoteEdit | null,
  loading: boolean
}

// Define the initial state using that type
const initialState: QuoteSlice = {
  index: [],
  edit: null,
  loading: false
}

export const counterSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    resetEdit: (state, action: PayloadAction<string>) => {
      state.edit = quoteEdit(action.payload);
    },
    editProperty: (state, action: PayloadAction<{[key: string]: any}>) => {
      const props = action.payload;
      if (state.edit == null) return;
      const current = state.index.filter(i => i.id == state?.edit?.id)[0];
      for (const k of Object.keys(props)) {
        state.edit[k].value = current != null
          ? (current[k] != props[k])
            ? props[k]
            : null
          : null;
      }
    }
  },

  extraReducers: (builder) => {

    builder.addCase(fetchAll.pending, (state, action) => {
      state.loading = true;
      state.index = []
    });
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      state.loading = false;
      state.index = action.payload
    });

    builder.addCase(fetchById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchById.fulfilled, (state, action) => {
      state.loading = false;
      state.index.filter(i => i.id == state?.edit?.id)[0] = action.payload
    });

    builder.addCase(deleteById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteById.fulfilled, (state, action) => {
      state.loading = false;
      state.index = state.index.filter(i => i.id != action.meta.arg);
    });

    builder.addCase(setPropertyById.pending, (state, action) => {
      const props = action.meta.arg.props;
      Object.keys(props).map(k => {
        if (state.edit === null) return;
        state.edit[k].loading = true
      });
    });
    builder.addCase(setPropertyById.rejected, (state, action) => {
      const props = action.meta.arg.props;
      Object.keys(props).map(k => {
        if (state.edit == null) return;
        state.edit[k].loading = true;
        state.edit[k].error = action.error.message;
      });
    });
    builder.addCase(setPropertyById.fulfilled, (state, action) => {
      const props = action.payload;
      Object.keys(props).map(k => {
        if (state.edit == null) return;
        state.edit[k].loading = false;
        state.edit[k].value = null;
        state.edit[k].error = null;
        state.index.filter(i => i.id == state?.edit?.id)[0][k] = action.payload[k];
      });
    });
  }
});



const {resetEdit, editProperty} = counterSlice.actions;
export {create, createAndOpen, createAndFetch, deleteById, fetchById, deleteByIdAndFetch, deleteByIdAndGotoList, fetchAll, resetEdit, setPropertyById, editProperty, saveById };

export const selectQuoteList = (state: RootState) => state.quote;
export const selectQuote = (id: string) => (state: RootState) => {
  const f = state.quote.index.filter(i => i.id === id);
  return (f.length > 0) ? f[0] : null;
}
export const selectEditQuote = (state: RootState) => state.quote.edit;

export default counterSlice.reducer
