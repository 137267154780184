import {Fragment, h} from "preact";
import style from "./FileItem.module.css";
import {FaFile} from "react-icons/fa";

// Actions
import {useAppDispatch} from "../../../hooks";

// Components
import FileMenu from "./FileMenu";
import FileStatus from "../FileStatus";
import File from "../File";


type FileItemProps = {
  file: File
}


export const FileItem = (props: FileItemProps) => {
  const { file } = props;
  const {qid, name, hash, size, status=FileStatus.Pending, content = {}} = file;
  const {items = [], sums = []} = content;
  const dispatch = useAppDispatch();

  return <div class={style.item}>
    <div class={style.symbol}>
      <FaFile size={24} />
    </div>
    <div class={style.info}>
      <div className={style.head}>
        <div class={style.name}>{name}</div>
      </div>
      <div class={style.detail}>
        <div class={style.size}>{Math.round(size/1000)}kiB</div>
        <div>{status}</div>
        {
          status === FileStatus.Done
            ? <Fragment>
                <div>{items.length} items</div>
                <div>{Object.keys(sums).length} sums</div>
              </Fragment>
            : null
        }
      </div>
    </div>
    <div class={style.options}>
      <FileMenu qid={qid} fid={hash} />
    </div>
  </div>
}

/*<div class={style.hash}>{hash}</div>*/

export default FileItem;