
import {h} from "preact";
import {useState} from "preact/compat";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import style from './FileMenu.module.css';
import {LuMoreHorizontal, LuMoreVertical} from "react-icons/lu";
import {deleteFile} from "../action/deleteFile";
import {Button} from "../../../components/input";
import {useAppDispatch} from "../../../hooks";
import {share} from "../FileSlice";

type FileMenuProps = {
  qid: string,
  fid: string
}

const FileMenu = (props: FileMenuProps) => {
  const {qid, fid} = props;
  const dispatch = useAppDispatch();

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="IconButton" aria-label="Customise options">
          <LuMoreVertical size={16} />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className={style.DropdownMenuContent} sideOffset={5}>
          <DropdownMenu.Item className={style.DropdownMenuItem}  onClick={() => dispatch(share({qid, fid}))}>
            Download
          </DropdownMenu.Item>
          <DropdownMenu.Separator className={style.DropdownMenuSeparator} />
          <DropdownMenu.Item className={style.DropdownMenuItem}  onClick={() => dispatch(deleteFile({id: qid, fid: fid}))} >
            Delete
          </DropdownMenu.Item>

          <DropdownMenu.Arrow className={style.DropdownMenuArrow} />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default FileMenu;
