
import {ComponentChildren, h} from "preact";
import style from "./Set.module.css";


export type SetProps = {
  children?: ComponentChildren
}

export const Set = ({children}: SetProps) => {
  return <div class={style.set}>
    {children}
  </div>
}

export default Set;
