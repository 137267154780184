
import {h} from "preact";
import style from "./FileList.module.css";
import {LuX} from "react-icons/lu";

// Actions
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {deleteFile, fetchAll, selectFiles, selectFilesAll, selectFilesByQuote} from "../FileSlice";

// Components
import {Button} from "../../../components/input";
import {useEffect} from "preact/compat";
import FileItem from "./FileItem";



type FileListProps = {
  qid?: string
}


export const FileList = (props: FileListProps) => {
  const {qid} = props;
  const files = useAppSelector(selectFiles);
  const index = useAppSelector(qid == null ? selectFilesAll : selectFilesByQuote(qid));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAll());
  }, [])

  if (files.loading) {
    return <div>Loading</div>;
  }

  if (files.error) {
    return <div>Could not load files.</div>;
  }

  return <div class={style.list}>
    {index.map(f => <FileItem key={`${f.qid}${f.hash}`} file={f} /> )}
  </div>
}


export default FileList;