
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumThicknessByWeight from "../sum/thicknessByWeight";


export type BreakdownByThicknessProps = {
  thickness: string
}

export function BreakdownByThickness(props: BreakdownByThicknessProps) {
  const thickness = parseFloat(props.thickness);

  return <Breakdown
    title={`${thickness}mm Sheet Material`}
    description={ "Undated, Weight (tonnes)" }
    filter={(quotes) => quotes.filter(q => q.start == null)}
    sum={(items) => sumThicknessByWeight(thickness, items).toString() }
  />
}

export default BreakdownByThickness;
