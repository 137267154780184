
import {url} from "../common";
import Plugin from "../Plugin";


export const QuotePlugin: Plugin = (handle) => {

  const push = async (auth: [string, string]) => {
    return fetch(
      [url, 'quote'].join('/'),
      {
        method: "POST",
        headers: {
          authorization: `bearer ${auth.join(' ')}`
        }
      });
  }

  const fetchAll = async (auth: [string, string]) => {
    return fetch(
      [url, 'quote'].join('/'),
      {
        method: "GET",
        headers: {
          authorization: `bearer ${auth.join(' ')}`
        }
      }).then(r => {
        handle(r);
        if (r.ok) return r.json();
        throw new Error(`${r.status}: could not fetch quotes.`);
    });
  }

  const fetchById = async (auth: [string, string], id: string) => {
    return fetch(
      [url, 'quote', id].join('/'),
      {
        method: "GET",
        headers: {
          authorization: `bearer ${auth.join(' ')}`
        }
      }).then(r => {
        handle(r);
        if (r.ok) return r.json();
        throw new Error(`${r.status}: could not fetch quote with id ${id}.`);
      });
    }

  const deleteById = async (auth: [string, string], id: string) => {
    return fetch(
      [url, 'quote', id].join('/'),
      {
        method: "DELETE",
        headers: {
          authorization: `bearer ${auth.join(' ')}`
        }
      }).then(r => {
        handle(r);
        if (r.ok) return;
        throw new Error(`${r.status}: could not delete quote with id ${id}.`);
      });
  }

  const putById = async (auth: [string, string], id: string, quote: { [key: string]: any }) => {
    return fetch(
      [url, 'quote', id].join('/'),
      {
        method: "PUT",
        headers: {
          authorization: `bearer ${auth.join(' ')}`
        },
        body: JSON.stringify(quote)
      }).then(r => {
        handle(r);
        if (r.ok) return true;
        throw new Error(`${r.status}: could not update quote with id ${id}.`);
      });
  }

  return {push, fetchAll, fetchById, deleteById, putById,}
}

export default QuotePlugin;
