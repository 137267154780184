
import {configureStore, Middleware} from '@reduxjs/toolkit'
import QuoteSlice from "./feature/quote/QuoteSlice";
import FileSlice from "./feature/file/FileSlice";
import IdentitySlice from "./feature/identity/IdentitySlice";


const logger: Middleware = store => next => action => {
  console.group(action.type)
  console.info('dispatching', action)
  const result = next(action)
  console.log('next state', store.getState())
  console.groupEnd()
  return result
}



const store = configureStore({
  reducer: {
    file: FileSlice,
    quote: QuoteSlice,
    identity: IdentitySlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;