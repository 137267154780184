
import {h} from "preact";
import Breakdown from "../lib/Breakdown";
import salesBySum from "../sum/salesBySum";
import SumType, {typeToLabel} from "../enums/sum-type";


export type BreakdownByLengthProps = {
  type: SumType
}

export function SalesUndated(props: BreakdownByLengthProps) {
  const {type} = props;

  return <Breakdown
    title={`Sales - ${typeToLabel(type)}`}
    description={ "Undated, Value (£)" }
    filter={(quotes) => quotes.filter(q => q.start == null).filter(q => type !== "paint" || q.painted)}
    sum={(items) => salesBySum(type, items).toString()}
  />
}

export default SalesUndated;
