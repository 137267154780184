
import FileStatus from "./FileStatus";


export type BaseItem = {
  "assembly": string,
  "part": string,
  "use": string,
  "quantity": number,
  "unit": number,
  "total": number,
  "paint": number
}

export type ThicknessProp = {
  thickness: number
}

export function hasThickness<X extends BaseItem>(item: X): item is (X & ThicknessProp) {
  return Object.hasOwn(item, "thickness");
}


export function withThickness<X extends BaseItem>(items: X[]): (X & ThicknessProp)[] {
  return items.filter(hasThickness);
}


export type WeightProp = {
  weight: {
    unit: number,
    total: number
  }
}

export function hasWeight<X extends BaseItem>(item: X): item is (X & WeightProp) {
  return Object.hasOwn(item, "weight");
}

export function withWeight<X extends BaseItem>(items: X[]): (X & WeightProp)[] {
  return items.filter(hasWeight);
}


export type LengthProp = {
  length: number
}

export function hasLength<X extends BaseItem>(item: X): item is (X & LengthProp) {
  return Object.hasOwn(item, "length")
}

export function withLength<X extends BaseItem>(items: X[]): (X & LengthProp)[] {
  return items.filter(hasLength);
}



export type File = {
  name: string,
  qid: string,
  hash: string,
  size: number,
  status?: FileStatus,
  content?: {
    items?: BaseItem[],
    sums?: {[key: string]: number};
  }
}


export default File;
