
import {h} from "preact";
import style from "./UploadItem.module.css"
import {LuAlertTriangle, LuCheck, LuLoader} from "react-icons/lu";


type UploadItemProps = {
  id: string,
  files: string[],
  loading: boolean,
  error?: string|null,
}


export const UploadItem = (props: UploadItemProps) => {
  const {
    id,
    files,
    error,
    loading
  } = props;

  return <div class={style.item}>
    <div class={style.detail}>
      <div class={style.files}>
        {files.map(f => <div key={f}>{f}</div>)}
      </div>
      { error != null ? <div class={style.error}>{error}</div> : null }
    </div>
    <div class={style.status}>
      <span style={style.statusIcon}>{loading ? <LuLoader /> : error ? <LuAlertTriangle />: <LuCheck />}</span>
      <span style={style.statusText}>{loading ? "Loading" : error ? "Error" : "OK"}</span>
    </div>
  </div>
}


export default UploadItem;