
import {h} from "preact";
import style from "./Loader.module.css";
import {LuLoader} from "react-icons/lu";

export const Loader = () => {
  return <div class={style.container}>
    <LuLoader />
  </div>
}
