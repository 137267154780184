
import {h} from "preact";
import style from "./Summary.module.css";
import {File} from "../../file/File";
import classNames from "classnames";
import {Link} from "preact-router/match";
import aggSalesBySum from "../sum/salesBySum";
import SumType, {typeToLabel} from "../enums/sum-type";


export type ThicknessByWeightProps = {
  type: SumType,
  interval: string,
  duration: number,
  dates: [number, number][],
  options?: string
  items: File[][],
  tabs: ({
    key: string,
    items: File[]
  } | null)[]
}


export const SalesBySum = (props: ThicknessByWeightProps) => {
  const {type, items, interval, duration, tabs=[], dates, options=""} = props;

  return <tr>
    <th class={classNames([style.name])}>{typeToLabel(type)}</th>
    {items.map((i, k) => <td key={k} class={classNames([style.number])}>
      <Link href={`/sales/${type}/?start=${dates[k][0]}&interval=${interval}&duration=1${options}`}>
        { aggSalesBySum(type, i) }
      </Link>
    </td>)}
    <td class={classNames([style.number])}>
      <Link href={`/sales/${type}/?start=${dates[0][0]}&interval=${interval}&duration=${duration}${options}`}>
        { aggSalesBySum(type, items.reduce((acc, cur) => [...acc, ...cur])) }
      </Link>
    </td>
    {tabs.map(k => {
        if (k == null) return null;
        return <td key={k} className={classNames([style.number, style.tab])}>
          <Link
            href={`/sales/${k.key}/${type}/?start=${dates[0][0]}&interval=${interval}&duration=${duration}`}>
            {aggSalesBySum(type, k.items)}
          </Link>
        </td>
      }
    )}
  </tr>
}
