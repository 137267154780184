
import {ComponentChildren, Fragment, h} from "preact"
import Placeholder from "../../../components/placeholder";
import {TiAdjustBrightness, TiArrowRepeat} from "react-icons/ti";
import {selectIdentity, sendCode, setId, verifyCode, setCode} from "../IdentitySlice";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import style from "./Login.module.css";
import {Button, Group, Input, Text} from "../../../components/input";
import {LuLogIn, LuSend} from "react-icons/lu";


export type LoginProps = {
  children?: ComponentChildren
}

export const Login = (props: LoginProps) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectIdentity);

  if (auth.id != null && auth.code != null && auth.verify.state) {
    return <Fragment>{children}</Fragment>;
  }

  return <Placeholder>
    <div class={style.container}>
      <div class={style.appBlock}>
        <span class={style.appInfo}>
          <span class={style.appName}>Solar-Man</span>
          <span class={style.appVersion}>v1.3.0</span>
        </span>
        <div class={style.appLogo}>
          <TiAdjustBrightness size={40} />
        </div>
      </div>
      <Group>
        <Input
          label="Email"
          error={auth.send.error}>
          <Text
            value={auth.id ?? ""}
            placeholder="e.g. bob@example.org"
            onChange={(v) => dispatch(setId(v))}
          />
          <Button label="Send" icon={<LuSend />} onClick={() => dispatch(sendCode(auth.id ?? ""))} loading={auth.send.loading} />
        </Input>
        <Input
          label="Password"
          error={auth.verify.error}>
          <Text
            value={auth.code ?? ""}
            placeholder="e.g. AHDJXS"
            onChange={(v) => dispatch(setCode(v))}
          />
          <Button label="Login" icon={<LuLogIn />} onClick={() => dispatch(verifyCode({id: auth.id ?? "", code: auth.code ?? ""}))} loading={auth.verify.loading} />
        </Input>
      </Group>
    </div>
  </Placeholder>
};

export default Login;
