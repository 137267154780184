
import {BaseItem, withWeight} from "../../file/File";


export function sumPartsByWeight(part: string, items: BaseItem[]) {
  const x = Math.round(
    withWeight(items)
      .filter(i => i.part === part)
      .reduce((acc, cur) => acc += cur.weight.total, 0) / 100
  ) / 10;
  return x != 0 ? x.toLocaleString('en-gb') : "-";
}

export default sumPartsByWeight;
