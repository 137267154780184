
import {BaseItem, withThickness, withWeight} from "../../file/File";

export function aggThicknessByWeight(thick: number, items: BaseItem[]) {
  const x = Math.round(
    withWeight(withThickness(items))
      .filter(i => i.thickness === thick)
      .reduce((acc, cur) => acc += cur.weight.total, 0) / 100
  ) / 10;
  return x != 0 ? x.toLocaleString('en-gb') : "-";
}

export default aggThicknessByWeight;
