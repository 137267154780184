
import {Fragment, h} from "preact";
import { Input } from "./Input";
import {FcCheckmark, FcUndo} from "react-icons/fc";
import {CgCheck, CgUndo} from "react-icons/cg";
import {BiCheck, BiCross, BiUndo} from "react-icons/bi";
import {IoCheckmark, IoClose, IoRepeat} from "react-icons/io5";
import placeholder from "../placeholder";
import classNames from "classnames";

export type TextProps = {
  class?: classNames.Argument,
  label?: string,
  placeholder?: string,
  maxLength?: number,
  minLength?: number,
  value: string,
  pending?: boolean,
  loading?: boolean,
  error?: boolean,
  onChange?: (value: string|null) => any
  onCommit?: (value: string|null) => any
  onRevert?: (value: string|null) => any
  onBlur?: (value: string|null) => any
}





export const Text = (props: TextProps) => {
  const {label, placeholder, error=false, maxLength=40, minLength = null, pending = false, loading = false, value, onChange, onCommit = () => null, onRevert = () => null, onBlur = () => null} = props;

  return <Fragment>
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      style={{ maxWidth: `${maxLength+2}ch`, minWidth: minLength != null ? `${minLength+2}ch` : null, flexBasis: `${maxLength+2}ch`}}
      class={classNames(props.class, {
        error: error
      })}
      onChange={
        (e) => {
          if (onChange != null) {
            onChange(
              e.currentTarget.value != ""
                ? e.currentTarget.value
                : null
            )
          }
        }
      }
    />
    { loading
       ? <IoRepeat />
       : pending
        ? <Fragment>
            <button style={{ padding: "0.2em 0.6em" }} onClick={() => onRevert(value)}><IoClose size={16} /></button>
            <button style={{ padding: "0.2em 0.6em" }} onClick={() => onCommit(value)}><IoCheckmark size={16}  /></button>
          </Fragment>
        : null
    }
  </Fragment>
}


export default Text;