
import {createRef, Fragment, h} from "preact";
import {route} from "preact-router";
import DatePicker from "react-datepicker";
import View from "../../../components/view";
import {useState} from "preact/compat";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {listFiles, fetchAll as fileFetch} from "../../file/FileSlice";
import {fetchAll, fetchAll as quoteFetch, selectQuoteList} from "../../quote/QuoteSlice";
import {useEffect} from "react";
import File, {BaseItem, withWeight, withThickness, withLength} from "../../file/File";
import style from "./Summary.module.css";
import {ThicknessByWeight} from "./ThicknessByWeight";
import {HeaderByMonth} from "./HeaderByMonth";
import {HeaderByQuarter} from "./HeaderByQuarter";
import classNames from "classnames";
import {PartsByWeight} from "./PartsByWeight";
import {PartsByQuantity} from "./PartsByQuantity";
import {PartsByLength} from "./PartsByLength";
import FoldingSurface from "../../../components/folding-surface/FoldingSurface";
import {Button, Checkbox} from "../../../components/input";
import * as I from "../../../components/input";
import {LuArrowLeft, LuRepeat} from "react-icons/lu";
import {Link} from "preact-router/match";
import {getDateRanges} from "../utils/dateRange";
import {IoOptions} from "react-icons/io5";
import {isOverrun} from "../filters/overrun";

type Interval = "month" | "quarter";
type Density = "small"|"medium"|"large";

type SummaryProps = {
  interval?: string,
  duration?: string,
  start?: string
}


function getItems(files: File[]): BaseItem[] {
  return files.reduce<BaseItem[]>((acc, cur) => [...acc, ...(cur?.content?.items ?? [])], []);
}

function quotesByRange(range: [number, number][], quotes: any[]): any[][] {
  return range.map(d => quotes.filter(q => q.start >= d[0] && q.start < d[1]));
}

function getFilesByQuotes(quotes: any[], files: File[]): File[] {
  return quotes.map(q => files.filter(i => i.qid === q.id)).reduce<File[]>((acc, cur) => [...acc, ...cur], []);
}

export const Summary = (props: SummaryProps) => {
  const now = new Date(Date.now());
  const ref = createRef<HTMLTableElement>();
  const params = new URLSearchParams(window.location.search);

  const getBooleanOption = (key: string) => {
    return params.get(key) == '1' ? true : params.get(key) == '0' ? false : null;
  }

  const getListOption = (key: string) => {
    const k = params.get(key) ?? "";
    return k.length > 0 ? k.split(',') : null
  }

  function getIntOption(key: string, min?: number): number | null {
    const k = params.get(key);
    const i = parseInt(k ?? "", 10);
    return k != null
      ? min != null
        ? Math.max(i, min)
        : i
      : null
  }

  function getEnumOption<T>(key: string, enums: string[]): T | null {
    return enums.includes(params.get(key) ?? "")
      ? (params.get(key) ?? "") as T
      : null;
  }

  function setBooleanOption(key: string, local: (value: boolean) => any) {
    return (value: boolean) => {
      local(value);
      params.set(key, value ? '1' : '0');
      route(window.location.pathname + '?' + params.toString());
    }
  }

  const [interval, setInterval] = useState<Interval>(getEnumOption('interval', ['month', 'quarter']) ?? "month");
  const [startDate, setStartDate] = useState(getIntOption('start') ?? Date.parse(`01 ${now.toLocaleString('default', { month: 'short' })} ${now.getFullYear()}`));
  const [density, setDensity] = useState<Density>(getEnumOption('density', ['small', 'medium', "large"]) ?? "medium");
  const [duration, setDuration] = useState(getIntOption('duration', 1) ?? 6);

  const [tabOverrun, setLocalTabOverrun] = useState<boolean>(getBooleanOption('tab-overrun') ?? true);
  const setTabOverrun = setBooleanOption('tab-overrun', setLocalTabOverrun);

  const [tabHold, setLocalTabHold] = useState<boolean>(getBooleanOption('tab-hold') ?? false);
  const setTabHold = setBooleanOption('tab-hold', setLocalTabHold);

  const [showOverrun, setLocalShowOverrun] = useState<boolean>(getBooleanOption('show-overrun') ?? true);
  const setShowOverrun = setBooleanOption('show-overrun', setLocalShowOverrun);

  const [showHold, setLocalShowHold] = useState<boolean>(getBooleanOption('show-hold') ?? false);
  const setShowHold = setBooleanOption('show-hold', setLocalShowHold);

  const [showUndated, setLocalShowUndated] = useState<boolean>(getBooleanOption('show-undated') ?? true);
  const setShowUndated = setBooleanOption('show-undated', setLocalShowUndated);

  const [filterStatus, setLocalFilterStatus] = useState<string[]>(getListOption('status') ?? ["Quote", "Order"]);
  const setFilterStatus = (key: string) => {
    return (value: boolean) => {
      const next = value
        ? filterStatus.includes(key)
          ? filterStatus
          : [...filterStatus, key]
        : filterStatus.filter(i => i != key);
      setLocalFilterStatus(next);
      params.set('status', next.join(','));
      route(window.location.pathname + '?' + params.toString());
    }
  }


  const dates = getDateRanges(startDate, duration, interval);
  const start = dates[0][0].valueOf();

  const files = useAppSelector(listFiles);
  const quotes = useAppSelector(selectQuoteList).index.filter(q => !["cancelled"].includes(q.status));

  const parts = files.reduce<string[]>((acc, cur) => {
    (cur?.content?.items ?? []).map(i => !acc.includes(i.part) && i.part != null ? acc.push(i.part) : null);
    return acc;
  }, [])
  const partsWithoutLength = Array.from(new Set(getItems(files).filter(i => !Object.hasOwn(i, "length") && i.part != null).map(i => i.part)));
  const partsWithLength = Array.from(new Set(withLength(getItems(files)).map(i => i.part)));
  const partsWithWeight = Array.from(new Set(withWeight(getItems(files)).map(i => i.part)));

  const thicknesses = Array.from(new Set(withThickness(getItems(files)).map(i => i.thickness)));

  const undated = quotes.filter(q => q.start === null);

  const hold = quotes
    .filter(q => q.status === "Hold")
    .filter(q => q.start !== null);

  const overrun = quotes.filter(isOverrun);

  const active = quotes.filter(q =>
    filterStatus.includes(q.status)
    && (!tabOverrun || !isOverrun(q))
    && (!tabHold || q.status != "Hold")
    && q.start !== null
  );

  const items = quotesByRange(dates, active).map(q => getItems(getFilesByQuotes(q, files)));
  const tabs =[
    ( showHold ? {key: 'hold', items: getItems(getFilesByQuotes(hold, files))} : null),
    ( showOverrun ? {key: 'overrun', items: getItems(getFilesByQuotes(overrun, files))} : null),
    ( showUndated ? {key: 'undated', items: getItems(getFilesByQuotes(undated, files))} : null),
  ];
  const cols = [
    (showHold ? <Link href={"/?status=Hold"}>Hold</Link> : null),
    (showOverrun ? <Link href={"/?valid=Overrun"}>Overrun</Link> : null),
    (showUndated ? <Link href={"/?valid=Undated"}>Undated</Link> : null),
  ];

  const filterOptions = `&tab-overrun=${tabOverrun ? "1" : "0"}&tab-hold=${tabHold ? "1" : "0"}&status=${encodeURIComponent(filterStatus.join(","))}`;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fileFetch());
    dispatch(quoteFetch());
  }, []);


  const fullWidth = dates.length + 4;

  return <View
    ctrl={[
      [
        <Button key="refresh" label="Refresh" icon={<LuRepeat />} onClick={() => dispatch(fetchAll())} />,
        /*,*/
      ]
    ]}>
    <FoldingSurface folds={[
      {
        icon: <IoOptions />,
        label: "Options",
        children: (
          <Fragment>
            <I.Set>
              <I.Input label={"Density"}>
                <select key={'density-picker'} value={density} onChange={i => {
                  const next = i.currentTarget.value as Density;
                  setDensity(next);
                  params.set('density', next.toString());
                  route(window.location.pathname + '?' + params.toString());
                }}>
                  <option value={"small"}>Small</option>
                  <option value={"medium"}>Medium</option>
                  <option value={"large"}>Large</option>
                </select>
              </I.Input>
            </I.Set>


            <I.Input label={"Tabulate Separately"}>
              <I.Set>
                <Checkbox label={"Overrun"} value={tabOverrun} onChange={setTabOverrun} />
              </I.Set>
            </I.Input>

            <I.Input label={"Show Columns"}>
              <I.Set>
                <Checkbox label={"Overrun"} value={showOverrun} onChange={setShowOverrun} />
                <Checkbox label={"Undated"} value={showUndated} onChange={setShowUndated} />
                <Checkbox label={"Hold"} value={showHold} onChange={setShowHold} />
              </I.Set>
            </I.Input>

            <I.Input label={"Filter By Status"}>
              <I.Set>
                <Checkbox label={"Quote"} value={filterStatus.includes("Quote")} onChange={setFilterStatus('Quote')} />
                <Checkbox label={"Order"} value={filterStatus.includes("Order")} onChange={setFilterStatus('Order')} />
                <Checkbox label={"Hold"} value={filterStatus.includes("Hold")} onChange={setFilterStatus('Hold')} />
                <Checkbox label={"Complete"} value={filterStatus.includes("Complete")} onChange={setFilterStatus('Complete')} />
                <Checkbox label={"Cancelled"} value={filterStatus.includes("Cancelled")} onChange={setFilterStatus('Cancelled')} />
              </I.Set>
            </I.Input>


          </Fragment>
        )
      }
    ]} >
      <select key={'interval-picker'} value={interval} onChange={i => {
        const next = i.currentTarget.value as Interval;
        setInterval(next);
        params.set('interval', next);
        route(window.location.pathname + '?' + params.toString());
      }}>
        <option value={"month"}>Month</option>
        <option value={"quarter"}>Quarter</option>
      </select>
      <DatePicker
        key={'start-date-picker'}
        selected={startDate}
        onChange={(date) => {
          setStartDate(date.valueOf());
          params.set('start', date.valueOf());
          route(window.location.pathname + '?' + params.toString());
        }}
        dateFormat={ interval === "month" ? "MM/yyyy" : "QQQ yyyy"}
        showMonthYearPicker={interval==="month"}
        showQuarterYearPicker={interval==="quarter"}
      />
      <input key={"duration-picker"} type={"number"} style={{ maxWidth: "6em", paddingRight: "1em", textAlign: "right" }} value={duration} onChange={(e) => {
        const next = e.currentTarget.valueAsNumber;
        if (Number.isNaN(next)) return;
        setDuration(next);
        params.set('duration', next.toString());
        route(window.location.pathname + '?' + params.toString());
      }} />
    </FoldingSurface>


    <table ref={ref} class={classNames([style.table, density === "small" ? style.densitySmall : density === "medium" ? style.densityMed : style.densityLarge])}>
      {
        interval === "month"
          ? <HeaderByMonth dates={dates} tabs={cols} status={filterStatus} />
          : <HeaderByQuarter dates={dates} tabs={cols} status={filterStatus} />
      }
      <tbody>
        <tr class={style.section}><th colSpan={fullWidth} class={style.heading}>
          <h4>Steel Sheet</h4>
          <span>tonnes</span>
        </th></tr>
        { thicknesses.map(thick =>
          <ThicknessByWeight
            key={`thickness-${thick}`}
            thickness={thick}
            interval={interval}
            duration={duration}
            dates={dates}
            options={filterOptions}
            items={items}
            tabs={tabs}
          />) }

        <tr class={style.section}><th colSpan={fullWidth} className={style.heading}>
          <h4>Parts By Weight</h4>
          <span>tonnes</span>
        </th></tr>
        { partsWithWeight.map(part =>
          <PartsByWeight
            key={`by-weight-${part}`}
            part={part}
            interval={interval}
            duration={duration}
            dates={dates}
            options={filterOptions}
            items={items}
            tabs={tabs}
          />) }

        <tr class={style.section}><th colSpan={fullWidth} className={style.heading}>
          <h4>Parts By Length</h4>
          <span>meters</span>
        </th></tr>
        { partsWithLength.map(part =>
          <PartsByLength
            key={`by-length-${part}`}
            part={part}
            interval={interval}
            duration={duration}
            dates={dates}
            options={filterOptions}
            items={items}
            tabs={tabs}
          />) }

        <tr class={style.section}><th colSpan={fullWidth} className={style.heading}>
          <h4>Parts By Quantity</h4>
          <span>no. of items</span>
        </th></tr>
        { partsWithoutLength.map(part =>
          <PartsByQuantity
            key={`by-quantity-${part}`}
            part={part}
            interval={interval}
            duration={duration}
            dates={dates}
            options={filterOptions}
            items={items}
            tabs={tabs}
          />) }
      </tbody>
    </table>
  </View>
}

export default Summary;
