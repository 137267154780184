
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../../api";
import {fetchAll} from "./fetchAll";
import {RootState} from "../../../store";
import {route} from "preact-router";

export const create = createAsyncThunk(
  'quote/create',
  async (a,thunk) => {
    const state = thunk.getState() as RootState;
    const r = await api(thunk.dispatch).quote.push([state.identity.id ?? "", state.identity.code ?? ""]);
    return (await r.json())?.id;
  }
)

export const createAndFetch = () => {
  return async (dispatch) => {
    await dispatch(create());
    return dispatch(fetchAll());
  }
}


export const createAndOpen = createAsyncThunk(
  'quote/createAndOpen',
  async (a,thunk) => {
    const state = thunk.getState() as RootState;
    const r = await api(thunk.dispatch).quote.push([state.identity.id ?? "", state.identity.code ?? ""]);
    const id = (await r.json())?.id;
    await thunk.dispatch(fetchAll());
    route(`/${id}`);
  }
)
