
import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import api from "../../../api";
import {fetchAll} from "../../quote/action/fetchAll";

export const deleteFile = createAsyncThunk(
  'file/deleteFile',
  async (p: { id: string, fid: string }, thunk) => {
    const state = thunk.getState() as RootState;
    await api(thunk.dispatch).file.deleteById([state.identity.id ?? "", state.identity.code ?? ""], p.id, p.fid);
    await thunk.dispatch(fetchAll());
  }
)
