
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Ajv, {JSONSchemaType} from "ajv";
import type { RootState } from '../../store'
import {sendCode} from "./action/sendCode";
import {verifyCode} from "./action/verifyCode";
import Unauthorised from "../../api/actions/Unauthorised";


const ajv = new Ajv();

type Identity = {
  id: string,
  auth: string
}

type IdentitySlice = {
  id?: string | null,
  code?: string | null
  send: {
    state: boolean,
    error?: string | null,
    loading: boolean
  }
  verify: {
    state: boolean,
    error?: string | null,
    loading: boolean,
  }
}

const authState: IdentitySlice = {
  id: "peter@steel-hub.ltd",
  code: "INVALID",
  send: {
    state: true,
    error: null,
    loading: false
  },
  verify: {
    state: true,
    error: null,
    loading: false,
  }
}

const IdentitySchema: JSONSchemaType<IdentitySlice> = {
  type: "object",
  properties: {
    id: {
      nullable: true,
      type: "string"
    },
    code: {
      type: "string",
      nullable: true
    },
    send: {
      type: "object",
      properties: {
        state: { type: "boolean" },
        error: { type: "string", nullable: true },
        loading: { type: "boolean" },
      },
      required: ["state", "loading"],
    },
    verify: {
      type: "object",
      properties: {
        state: { type: "boolean" },
        error: { type: "string", nullable: true },
        loading: { type: "boolean" },
      },
      required: ["state", "loading"],
    },
  },
  required: ["send", "verify" ]
}
const validIdentitySchema = ajv.compile(IdentitySchema);

const initialState: IdentitySlice = {
  id: null,
  code: null,
  send: {
    state: false,
    error: null,
    loading: false
  },
  verify: {
    state: false,
    error: null,
    loading: false,
  }
}

let localState;
try {
  localState = JSON.parse(localStorage.getItem('auth') ?? "");
  // if local state is not valid, negate it
  if (!validIdentitySchema(localState)) {
    localState = null;
    localStorage.removeItem('auth');
  }
} catch (e) {
  localState = null;
  localStorage.removeItem('auth');
}

export const counterSlice = createSlice({
  name: 'identity',
  initialState:  localState ?? initialState,
  reducers: {
    setId: (state, action: PayloadAction<string|null>) => {
      state.id = action.payload;
    },
    setCode: (state, action: PayloadAction<string|null>) => {
      state.code = action.payload;
    },
    logout: (state) => {
      state.code = null;
      state.send = {
        state: false,
        error: null,
        loading: false
      };
      state.verify = {
        state: false,
        error: null,
        loading: false
      };
      localStorage.removeItem('auth');
    }
  },
  extraReducers: (builder) => {

    builder.addCase(Unauthorised, (state) => {
      state.verify = {
        state: false,
        loading: false,
        error: "Access token has expired.",
      }
    });

    builder.addCase(sendCode.pending, (state, action) => {
      state.send = {
        state: false,
        loading: true,
        error: null,
      }
    });

    builder.addCase(sendCode.fulfilled, (state, action) => {
      state.send = {
        state: true,
        loading: false,
        error: null,
      }
    });

    builder.addCase(sendCode.rejected, (state, action) => {
      console.log("test");
      console.log(action.error);
      state.send = {
        state: false,
        loading: false,
        error: typeof action?.error?.message == "string" ? action?.error?.message : "Could not send access token.",
      }
    });

    builder.addCase(verifyCode.pending, (state, action) => {
      state.verify = {
        state: false,
        loading: true,
        error: null,
      }
    });

    builder.addCase(verifyCode.fulfilled, (state, action) => {
      state.verify = {
        state: true,
        loading: false,
        error: null,
      }
      localStorage.setItem('auth', JSON.stringify(state));
    });

    builder.addCase(verifyCode.rejected, (state, action) => {
      state.verify = {
        state: false,
        loading: false,
        error: "Invalid access token.",
      }
    });
  }
})

const { logout, setId, setCode } = counterSlice.actions;
export {sendCode, verifyCode, setId, setCode, logout};

export const selectIdentity = (state: RootState) => state.identity;

export default counterSlice.reducer
