
import {ComponentChildren, h} from "preact";
import style from "../component/Summary.module.css";
import {BaseItem, withThickness, withWeight} from "../../file/File";
import {formatQuarterRange, quarter} from "../utils/dateRange";
import classNames from "classnames";
import {Link} from "preact-router/match";

export type HeaderByQuarterProps = {
  dates: [number, number][],
  status?: string[],
  tabs: (ComponentChildren|string|null)[],
}


function getStartDate(dates: [number, number][]) {
  return (new Date(dates[0][0])).toLocaleString('default', { year: "numeric", month: "numeric" });
}
function getEndDate(dates: [number, number][]) {
  return (new Date(dates[dates.length - 1][0])).toLocaleString('default', { year: "numeric", month: "numeric" });
}

export const HeaderByQuarter = (props: HeaderByQuarterProps) => {
  const {dates, tabs=[], status=["Quote","Order"]} = props;

  return <thead>
    <tr>
      <th className={style.title} rowSpan={2}>
        <h3>Sales Quantities</h3>
        <span>{formatQuarterRange(dates)}</span>
      </th>
      {
        dates.map((d, i) => {
          const k = new Date(d[0]);
          const qt = quarter(d[0]);

          if (i !== 0 && qt !== 1) return;
          return <th key={i} colSpan={Math.min((4 - qt + 1), dates.length - i )} class={style.group}>{k.getFullYear()}</th>
        })
      }
      <th />
      {tabs.map((k, i) => k == null ? null : <th key={i} />)}
    </tr>
    <tr>
      { dates.map((d, i) => <th key={i}><Link href={`/?status=${encodeURIComponent(status.join(','))}&start-min=${d[0]}&start-max=${d[1]}`}>{`Q${quarter(d[0])}`}</Link></th>) }
      <th><Link href={`/?status=${encodeURIComponent(status.join(','))}&start-min=${dates[0][0]}&start-max=${dates[dates.length-1][1]}`}>Total</Link></th>
      {tabs.map((k, i) => k == null ? null : <th key={i} class={classNames(style.tab)}>{k}</th>)}
    </tr>
  </thead>
}
