

export function useStatusFilter() {
  const params = new URLSearchParams(window.location.search);
  const status = params.get('status');
  if (status == null) return ["Quote", "Order"];
  return status.split(",");
}

export default useStatusFilter;
