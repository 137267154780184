
import {h} from "preact";
import View from "../../../components/view";
import {useState} from "preact/compat";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {listFiles, fetchAll as fileFetch} from "../../file/FileSlice";
import {fetchAll, fetchAll as quoteFetch, selectQuoteList} from "../../quote/QuoteSlice";
import {useEffect} from "react";
import {withWeight, withThickness, withLength, BaseItem} from "../../file/File";
import style from "./Summary.module.css";
import classNames from "classnames";
import {Button} from "../../../components/input";
import {LuRepeat} from "react-icons/lu";

import Interval from "../enums/interval";
import Density from "../enums/density";
import {getDateRanges, formatDateRange} from "../utils/dateRange";
import getItems from "../utils/items";
import {Link} from "preact-router/match";




export type BreakdownProps = {
  title?: string,
  description?: string,
  filter?: (l: any[]) => any[],
  sum?: (items: BaseItem[]) => string
}


export const Breakdown = (props: BreakdownProps) => {
  const { title="", description="", filter = (q => q), sum = (items) => "?" } = props;

  const [density, setDensity] = useState<Density>("medium");

  const quotes = filter(useAppSelector(selectQuoteList).index);
  const files = useAppSelector(listFiles);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fileFetch());
    dispatch(quoteFetch());
  }, []);

  return <View
    ctrl={[
      [
        <Button key="refresh" label="Refresh" icon={<LuRepeat />} onClick={() => dispatch(fetchAll())} />,
      ]
    ]}>
    <h3>{title}</h3>
    <p>{description}</p>

    <table class={classNames([style.table, density === "small" ? style.densitySmall : density === "medium" ? style.densityMed : style.densityLarge])}>
      <tbody>
      {
        quotes.map(q => {
          return <tr key={q.id}>
            <td><Link href={`/${q.id}/`}>{ (q.name ?? "") == "" ? "New Quote" : q.name }</Link></td>
            <td>{
              sum(getItems(files.filter(f => f.qid === q.id)))
            }</td>
          </tr>
        })
      }
      </tbody>
      <tfoot>
        <tr>
          <td>Total</td>
          <td>{ sum(getItems(files.filter(f => quotes.map(q => q.id).includes(f.qid)))) }</td>
        </tr>
      </tfoot>
    </table>
  </View>
}

export default Breakdown;
