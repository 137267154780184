
import {ComponentChildren, h} from "preact";
import style from "./Group.module.css";


export type GroupProps = {
  heading?: string
  children?: ComponentChildren
}

export const Group = (props: GroupProps) => {
  const { children, heading } = props;

  return <div class={style.group}>
    { heading != null ? <h3>{ heading }</h3> : null }
    <div>
      {children}
    </div>
  </div>
};

export default Group;
