
import {h} from "preact";
import style from "./File.module.css";
import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {BiUpload} from "react-icons/bi";

export type FileProps = {
  onUpload?: (files: (File & { content: ArrayBuffer|string})[]) => any
}


export function File(props: FileProps) {
  const {onUpload = () => null} = props;

  const onDrop = useCallback((acceptedFiles) => {
      return Promise.all(
        acceptedFiles.map(
          (file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()

              reader.onabort = () => reject('file reading was aborted')
              reader.onerror = () => reject('file reading has failed')
              reader.onload = () => resolve(Object.assign(file,{ content: reader.result }));
              reader.readAsArrayBuffer(file)
            });
          }
      )).then((files) => onUpload(files));
  }, []);
  const {getRootProps, getInputProps, open, isDragAccept} = useDropzone({onDrop, noClick: true} as any)


  return (
    <div class={[style.container, isDragAccept ? style.dragAccepted : null].join(' ')} {...getRootProps()}>
      <input {...(getInputProps() as any)} />
      <div class={style.icon} onClick={open}><BiUpload size={40} /></div>
      <p class={style.instruction}>Drop files here to upload</p>
      <button className={style.button} type="button" onClick={open}>
        <span>Select Files</span>
      </button>
    </div>
  )
}
