import { h, ComponentChildren } from 'preact';
import { Link } from 'preact-router/match';
import { TiAdjustBrightness } from "react-icons/ti";
import style from './style.css';
import {useAppDispatch} from "../../hooks";
import {logout} from "../../feature/identity/IdentitySlice";
import {Button} from "../input";
import {LuLogOut, LuSquare} from "react-icons/lu";

type HeaderProps = {
	children?: ComponentChildren
}

/*
	<Link activeClassName={style.active} href="/materials">
		Materials
	</Link>
	<Link activeClassName={style.active} href="/sales">
		Sales
	</Link>
 */

const Header = (props: HeaderProps) => {
	const dispatch = useAppDispatch();

	return <header class={style.header}>
		<div class={style.inner}>
			<div class={style.main}>
				<nav class={style.nav}>
					<Link activeClassName={style.active} href="/">Quotes</Link>
					<Link activeClassName={style.active} href="/materials">Material</Link>
					<Link activeClassName={style.active} href="/sales">Sales</Link>
				</nav>
				<nav class={style.nav}>
					<Button label="Logout" icon={<LuLogOut />} onClick={() => dispatch(logout())} />
				</nav>
			</div>
			{props.children}
		</div>
	</header>
};

export default Header;
