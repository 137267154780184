
import {h} from "preact";
import Breakdown from "../lib/Breakdown";
import salesBySum from "../sum/salesBySum";
import useDateRange from "../hooks/useDateRange";
import {formatDateRange} from "../utils/dateRange";
import useStatusFilter from "../hooks/useStatusFilter";
import useTabOverrun from "../hooks/useTabOverrun";
import {isOverrun} from "../filters/overrun";
import {typeToLabel, SumType} from "../enums/sum-type";


export type BreakdownByLengthProps = {
  type: SumType
}

export function SalesBreakdown(props: BreakdownByLengthProps) {
  const {type} = props;
  const [dates, [], [interval], []] = useDateRange();
  const status = useStatusFilter();
  const tabOverrun = useTabOverrun();

  return <Breakdown
    title={`Sales - ${typeToLabel(type)}`}
    description={ `${formatDateRange(dates, interval)}, Value (£) ` }
    filter={(quotes) => quotes.filter(q =>
      q.start >= dates[0][0]
      && (type !== "paint" || q.painted)
      && q.start < dates[dates.length - 1][1]
      && status.includes(q.status)
      && (!tabOverrun || !isOverrun(q))
    )}
    sum={(items) => salesBySum(type, items).toString()}
  />
}

export default SalesBreakdown;

