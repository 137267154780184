
import Interval from "../enums/interval";

export function getDateRanges(start: number, duration: number, interval: Interval = "month"): [number, number][] {
  const r: [number, number][] = [];
  let p = start;
  let d = new Date(start);

  if (interval === "month") {
    d = new Date(d.getFullYear(), d.getMonth(), 1);
  }

  if (interval === "quarter") {
    const q = quarter(d.valueOf());
    d = new Date(d.getFullYear(), (q - 1) * 3, 1);
    p = d.valueOf();
  }

  for (let i = 0; i < duration; i += 1) {
    switch (interval) {
      case "month":
        d.setMonth(d.getMonth() + 1);
        break;
      case "quarter":
        d.setMonth(d.getMonth() + 3);
        break;
    }
    r.push([p, d.valueOf()]);
    p = d.valueOf();
  }

  return r;
}



function formatMonth(date: number) {
  return (new Date(date).toLocaleString('default', { year: "numeric", month: "short" }));
}

export function quarter(date: number): number {
  return Math.floor((new Date(date)).getMonth() / 3) + 1;
}

function formatQuarter(date: number) {
  return `Q${quarter(date)} ` + (new Date(date).toLocaleString('default', { year: "numeric" }));
}

export function formatMonthRange(dates: [number, number][]) {
  if (dates.length > 1) {
    return `${formatMonth(dates[0][0])} - ${formatMonth(dates[dates.length - 1][0])}`
  }
  return formatMonth(dates[0][0])
}

export function formatQuarterRange(dates: [number, number][]) {
  if (dates.length > 1) {
    return `${formatQuarter(dates[0][0])} - ${formatQuarter(dates[dates.length - 1][0])}`
  }
  return formatQuarter(dates[0][0])
}

export function formatDateRange(dates: [number, number][], interval: Interval = "month") {
  if (interval == "month") return formatMonthRange(dates);
  return formatQuarterRange(dates);
}
