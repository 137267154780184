
import {ComponentChildren, h} from "preact";

export type TextProps = {
  value: string,
  onChange: (value: string|null) => any,
  children: ComponentChildren
}

export const Select = (props: TextProps) => {
  const {value, onChange, children} = props;

  return <select
    value={value}
    onChange={
      (e) => onChange(e.currentTarget.value != "" ? e.currentTarget.value : null)}
  >
    { children }
  </select>;
}

export default Select;