
import {ComponentChildren, h} from "preact";
import classNames from "classnames";
import style from "./Button.module.css";
import {Loader} from "./Loader";
import {IconType} from "react-icons";


export type ButtonProps = {
  label: string,
  loading?: boolean,
  danger?: boolean,
  disabled?: boolean,
  icon?: ComponentChildren
  children?: ComponentChildren
  onClick?: () => any,
}

export const Button = (props: ButtonProps) => {
  const {
    icon,
    label,
    children,
    onClick,
    loading = false,
    danger = false,
    disabled = false,
  } = props;

  if (loading) return <Loader />

  return <button
    class={classNames(style.button, {
      [style.danger]: danger,
      [style.disabled]: disabled
    })}
    onClick={disabled ? undefined : onClick}
  >
    {icon != null ? <span class={style.icon}>{icon}</span> : null}
    {label}
  </button>
}

export default Button;