
import {h, ComponentChildren} from "preact";
import style from "./FoldingSurface.module.css";
import {useState} from "preact/compat";
import {Button} from "../input";
import {IoClose} from "react-icons/io5";


type Fold = {
  icon: ComponentChildren,
  label: string
  children?: ComponentChildren,
}

type FoldingSurfaceProps = {
  children?: ComponentChildren,
  folds?: Fold[]
};


export const FoldingSurface = (props: FoldingSurfaceProps) => {
  const { children, folds = [] } = props;
  const [fold, setFold] = useState<number|null>(null);

  return <div class={style.surface}>
    <div class={style.bar}>
      <div class={style.barContent}>
        { children }
      </div>
      <div class={style.barTriggers}>
        {
          folds.map(
            (f, i) =>
              <Button
                key={i}
                label={f.label}
                icon={fold !== i ? f.icon : <IoClose />}
                onClick={() => setFold(fold !== i ? i : null)}
              />
          )
        }
      </div>
    </div>
    { fold !== null ? <div class={style.content}>{folds[fold].children}</div> : null }
    <div class={style.foldLine} />
  </div>
};

export default FoldingSurface;
