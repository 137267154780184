
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumThicknessByWeight from "../sum/thicknessByWeight";
import {isOverrun} from "../filters/overrun";


export type BreakdownByThicknessProps = {
  thickness: string
}

export function BreakdownByThickness(props: BreakdownByThicknessProps) {
  const thickness = parseFloat(props.thickness);

  return <Breakdown
    title={`${thickness}mm Sheet Material`}
    description={ "Overrun, Weight (tonnes)" }
    filter={(quotes) => quotes.filter(isOverrun)}
    sum={(items) => sumThicknessByWeight(thickness, items).toString() }
  />
}

export default BreakdownByThickness;
