
import {ComponentChildren, h} from 'preact';
import { Link } from 'preact-router/match';
import itemStyle from './list-item.module.css';
import {useAppDispatch} from "../../../hooks";
import {deleteById, deleteByIdAndFetch} from "../QuoteSlice";
import {Button} from "../../../components/input";
import {LuArrowRight, LuBookOpen, LuFile, LuMoreVertical, LuSquareDot, LuTrash, LuView} from "react-icons/lu";
import {FaFile, FaFolder, FaFolderOpen, FaSquare} from "react-icons/fa";

type QuoteItemProps = {
	id: string
	name: string
	client: string
	postCode: string
	issued: Date,
	start: Date,
	status: "InProgress" | "OnHold" | "Accepted" | "Cancelled"
	files: []
}

const QuoteListItem = (d: QuoteItemProps) => {
	const dispatch = useAppDispatch();

	return <div class={itemStyle.item}>
		<div class={itemStyle.status} style={{ display: "none" }}>
			<Status value={d.status} />
		</div>
		<Link class={itemStyle.nav} href={`/${d.id}`}>
			<div class={itemStyle.symbol}>
				<div class={itemStyle.symbolBase}><FaFolder size={24} /></div>
				<div class={itemStyle.symbolHover}><FaFolderOpen size={24} /></div>
			</div>
			<div class={itemStyle.details}>
				<div class={itemStyle.primary}>
					{d?.name != null  && d?.name != "" ? d.name : " New Quote "}
				</div>
				<div class={itemStyle.secondary}>
					{d?.client != null ? <span>{d.client}</span> : null}
					{d?.postCode != null ? <span>{d.postCode}</span> : null}
				</div>
			</div>
		</Link>
		<div class={itemStyle.ctrl}>
			<LuMoreVertical size={16} />
		</div>
	</div>;
};


type TagProps = {
	children?: ComponentChildren
}

const Tag = ({children}: TagProps) => (
	<div class={itemStyle.tag}>
		{children}
	</div>
)

type IndicatorProps = {
	class?: string
}

const Indicator = (props: IndicatorProps) => (
	<div class={itemStyle.indicator + " " + props.class} />
)


type StatusProps = {
	value: "InProgress" | "OnHold" | "Accepted" | "Cancelled"
}

const Status = ({value}: StatusProps) => {
	switch (value) {
		case "InProgress":
			return <Indicator class={itemStyle.progress} />
		case "OnHold":
			return <Indicator class={itemStyle.hold} />
		case "Accepted":
			return <Indicator class={itemStyle.accepted} />
		case "Cancelled":
			return <Indicator class={itemStyle.cancelled} />
	}
	return <Indicator class={itemStyle.hold} />
}


export default QuoteListItem;
