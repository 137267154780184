
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumPartsByQuantity from "../sum/partsByQuantity";
import {isOverrun} from "../filters/overrun";


export type BreakdownByQuantityProps = {
  part: string
}

export function BreakdownByQuantity(props: BreakdownByQuantityProps) {
  const {part} = props;

  return <Breakdown
    title={`${props.part}`}
    description={ "Overrun, Quantity (no. of items)" }
    filter={(quotes) => quotes.filter(isOverrun)}
    sum={(items) => sumPartsByQuantity(part, items).toString()}
  />
}

export default BreakdownByQuantity;
