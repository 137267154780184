
import {url} from "../common";
import Plugin from "../Plugin";


export const FilePlugin: Plugin = (handle) => {

  const fetchAll = async (auth: [string, string]) => {
    return fetch(
      [url, 'file'].join('/'),
      {
        method: "GET",
        headers: {
          authorization: `bearer ${auth.join(' ')}`
        }
      }).then(r => {
      if (r.ok) return r.json();
      throw new Error(`${r.status}: could not fetch quotes.`);
    });
  }

  const upload = async (auth: [string, string], id: string, files: (File & {
    content: ArrayBuffer | string
  })[]) => {
    const form = new FormData();
    for (const file of files) {
      form.append("files", file, file.name)
    }

    return fetch(
      [url, 'file', id].join('/'),
      {
        method: "POST",
        headers: {
          authorization: `bearer ${auth.join(' ')}`
        },
        body: form
      }).then(r => {
      if (r.ok) return true;
      throw new Error(`${r.status}: could not upload files to quote with id ${id}.`);
    });
  }

  const deleteById = async (auth: [string, string], qid: string, fid: string) => {
    return fetch(
      [url, 'file', qid, fid].join('/'),
      {
        method: "DELETE",
        headers: {
          authorization: `bearer ${auth.join(' ')}`
        }
      }).then(r => {
      if (r.ok) return true;
      throw new Error(`${r.status}: could not delete file with id ${qid}.${fid}.`);
    });
  }

  const share = async (auth: [string, string], qid: string, fid: string) => {
    return fetch(
      [url, 'file', qid, fid].join('/'),
      {
        method: "POST",
        headers: {
          authorization: `bearer ${auth.join(' ')}`
        }
      }).then(r => {
        handle(r);
        if (r.ok) return r.json();
        throw new Error(`${r.status}: could not prepare download for ${qid}.${fid}`);
      });
  }

  return {fetchAll, upload, deleteById, share};
}

export default FilePlugin;
