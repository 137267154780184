
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../../api";

export const verifyCode = createAsyncThunk(
  'identity/verifyCode',
  async (params: {id: string, code: string}, thunk) => {
    const {id, code} = params;
    await api(thunk.dispatch).auth.verifyCode(id, code);
  }
)

