
import {ComponentChildren, h} from "preact";
import {BaseItem, withThickness, withWeight} from "../../file/File";
import style from "../component/Summary.module.css";
import {Link} from "preact-router/match";
import classNames from "classnames";

export type HeaderByMonthProps = {
  dates: [number, number][],
  status?: string[],
  tabs: (ComponentChildren|string|null)[],
}


function getStartDate(dates: [number, number][]) {
  return (new Date(dates[0][0])).toLocaleString('default', { year: "numeric", month: "short" });
}
function getEndDate(dates: [number, number][]) {
  return (new Date(dates[dates.length - 1][0])).toLocaleString('default', { year: "numeric", month: "short" });
}

export const HeaderByMonth = (props: HeaderByMonthProps) => {
  const {dates, tabs=[], status=["Quote","Order"]} = props;

  return <thead>
    <tr>
      <th class={style.title} rowSpan={2}>
        <h3>Sales Quantities</h3>
        <span>{ dates.length > 1 ? `${getStartDate(dates)} - ${getEndDate(dates)}` : getStartDate(dates) }</span>
      </th>
      {
        dates.map((d, i) => {
          const k = new Date(d[0]);
          if (i !== 0 && k.getMonth() !== 0) return;
          return <th key={i} colSpan={Math.min((12 - k.getMonth()), dates.length - i )} class={style.group}>{k.getFullYear()}</th>
        })
      }
      <th />
      {tabs.map((k, i) => k == null ? null : <th key={i} />)}
    </tr>
    <tr>
      { dates.map((d, i) => <th key={i}><Link href={`/?status=${encodeURIComponent(status.join(','))}&start-min=${d[0]}&start-max=${d[1]}`}>{ (new Date(d[0])).toLocaleString('default', { month: 'short' }) }</Link></th>) }
      <th><Link href={`/?status=${encodeURIComponent(status.join(','))}&start-min=${dates[0][0]}&start-max=${dates[dates.length-1][1]}`}>Total</Link></th>
      {tabs.map((k, i) => k == null ? null : <th key={i} class={classNames(style.tab)}>{k}</th>)}
    </tr>
  </thead>
}
