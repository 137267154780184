
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumPartsByQuantity from "../sum/partsByQuantity";


export type BreakdownByQuantityProps = {
  part: string
}

export function BreakdownByQuantity(props: BreakdownByQuantityProps) {
  const {part} = props;

  return <Breakdown
    title={`${props.part}`}
    description={ "Undated, Quantity (no. of items)" }
    filter={(quotes) => quotes.filter(q => q.start == null)}
    sum={(items) => sumPartsByQuantity(part, items).toString()}
  />
}

export default BreakdownByQuantity;
