
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../../api";
import {RootState} from "../../../store";
import {File} from "../../../components/input";
import {fetchAll} from "../../quote/action/fetchAll";

export const upload = createAsyncThunk(
  'file/upload',
  async (p: { id: string, files: (File & { content: ArrayBuffer|string})[] }, thunk) => {
    const state = thunk.getState() as RootState;
    return api(thunk.dispatch).file.upload([state.identity.id ?? "", state.identity.code ?? ""], p.id, p.files);
  }
)


export const uploadAndRefresh = createAsyncThunk(
  'file/uploadAndRefresh',
  async (p: { id: string, files: (File & { content: ArrayBuffer|string})[] }, thunk) => {
    await thunk.dispatch(upload(p));
    await thunk.dispatch(fetchAll());
  }
)
