
import { h } from 'preact';
import QuoteList from "../../feature/quote/component/list";



const QuotePageList = () => {
	return <QuoteList />
};

export default QuotePageList;
