
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumPartsByLength from "../sum/partsByLength";
import sumPartsByQuantity from "../sum/partsByQuantity";
import useDateRange from "../hooks/useDateRange";
import {formatDateRange} from "../utils/dateRange";
import useStatusFilter from "../hooks/useStatusFilter";
import useTabOverrun from "../hooks/useTabOverrun";
import {isOverrun} from "../filters/overrun";


export type BreakdownByQuantityProps = {
  part: string
}

export function BreakdownByQuantity(props: BreakdownByQuantityProps) {
  const {part} = props;
  const [dates, [], [interval], []] = useDateRange();
  const status = useStatusFilter();
  const tabOverrun = useTabOverrun();

  return <Breakdown
    title={`${props.part}`}
    description={ formatDateRange(dates, interval) + ", Quantity (no. of items)" }
    filter={(quotes) => quotes.filter(q =>
      q.start >= dates[0][0]
      && q.start < dates[dates.length - 1][1]
      && status.includes(q.status)
      && (!tabOverrun || !isOverrun(q))
    )}
    sum={(items) => sumPartsByQuantity(part, items).toString()}
  />
}

export default BreakdownByQuantity;
