
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type { RootState } from '../../store'

import {fetchAll} from "./action/fetchAll";
import {upload, uploadAndRefresh} from "./action/upload";
import {share} from "./action/share";
import {deleteFile} from "./action/deleteFile";

import File from "./File";

type Upload = {
  id: string,
  files: string[],
  loading: boolean,
  error?: string|null,
}

type Index = {
  files: { [key: string]: { [key: string]: File } },
  loading: boolean,
  error?: string|null,
}

type FileSlice = {
  index: Index,
  uploads: Upload[],
}

const initialState: FileSlice = {
  index: {
    loading: false,
    error: null,
    files: {}
  },
  uploads: [],
}


export const fileSlice = createSlice({
  name: 'file',
  initialState,

  reducers: {
    resetUploads: (state, action: PayloadAction<string>) => {
      state.uploads = [];
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAll.pending, (state, action) => {
      state.index =  {
        loading: true,
        error: null,
        files: {},
      };
    });
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.index =  {
        loading: false,
        error: "Could not load files",
        files: {},
      };
    });
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      state.index =  {
        loading: false,
        error: null,
        files: action.payload,
      };
    });

    builder.addCase(upload.pending, (state, action) => {
      state.uploads.push({
        id: action.meta.requestId,
        files: action.meta.arg.files.map(f => f.name),
        loading: true
      });
    });
    builder.addCase(upload.rejected, (state, action) => {
      state.uploads.filter(u => u.id == action.meta.requestId)[0].loading = false;
      state.uploads.filter(u => u.id == action.meta.requestId)[0].error = action.error.message;
    });
    builder.addCase(upload.fulfilled, (state, action) => {
      state.uploads = state.uploads.filter(u => u.id !== action.meta.requestId);
    });
  }
});


const { resetUploads } = fileSlice.actions;
export { resetUploads, upload, uploadAndRefresh, deleteFile, fetchAll, share };

export const selectFiles = (state: RootState) => state.file.index;
export const selectFilesAll = (state: RootState) => Object.values(state.file.index.files)
  .reduce((acc, current) => [...acc, ...Object.values(current)], []);
export const selectFilesByQuote = (qid: string) => (state: RootState) => {
  return Object.values(state.file.index.files?.[qid] ?? []);
}
export const selectFileById = (qid: string, fid: string) => (state: RootState) => {
  return state.file.index.files?.[qid]?.[fid] ?? [];
}

export const selectUploads = (state: RootState) => state.file.uploads;
export const selectUpload = (id: string) => (state: RootState) => {
  const f = state.file.uploads.filter(i => i.id === id);
  return (f.length > 0) ? f[0] : null;
}

export const listFiles = (state: RootState) => {
  return Object.values(state.file.index.files).reduce<File[]>((acc, cur) => [...acc, ...Object.values(cur)], []);
}


export default fileSlice.reducer
