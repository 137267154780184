
import {h} from "preact";
import Breakdown from "../lib/Breakdown";
import salesBySum from "../sum/salesBySum";
import {isOverrun} from "../filters/overrun";
import SumType, {typeToLabel} from "../enums/sum-type";


export type BreakdownByLengthProps = {
  type: SumType
}

export function SalesOverrun(props: BreakdownByLengthProps) {
  const {type} = props;

  return <Breakdown
    title={`Sales - ${typeToLabel(type)}`}
    description={ `Overrun, Value (£)` }
    filter={(quotes) => quotes.filter(isOverrun).filter(q => type !== "paint" || q.painted)}
    sum={(items) => salesBySum(type, items).toString()}
  />
}

export default SalesOverrun;
