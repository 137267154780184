
import {ComponentChildren, h} from "preact";
import style from "./Input.module.css";
import {TiArrowRepeat} from "react-icons/ti";
import {LuLoader, LuRepeat} from "react-icons/lu";

export type InputProps = {
  label?: string,
  error?: string | null,
  children?: ComponentChildren
}

export const Input = (props: InputProps) => {
  const {label, error, children} = props;

  return <div class={style.group}>
    <span>
      { label != null
        ? <label class={style.label}>{label}</label>
        : null
      }

    </span>
    <div class={style.input}>
      {children}
    </div>
    { error != null
      ? <span class={style.error}>{error}</span>
      : null
    }
  </div>
}

export default Input;