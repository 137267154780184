
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../../api";
import {RootState} from "../../../store";
import {fetchAll, resetEdit} from "../QuoteSlice";

const auth = (thunk: any): [string, string] => {
  const state = thunk.getState() as RootState;
  return [state.identity.id ?? "", state.identity.code ?? ""]
}

export const setPropertyById = createAsyncThunk(
  'quote/setPropertyById',
  async (p: {id: string, props: {[key: string]: any}}, thunk) => {
    await api(thunk.dispatch).quote.putById(auth(thunk), p.id, p.props);
    return p.props;
  }
)

export const saveById = createAsyncThunk(
  'quote/saveById',
  async (p, thunk) => {
    const state = (thunk.getState() as RootState).quote;
    if (state.edit == null) return;
    const id = state.edit.id;

    const n: any = {};
    for (const k of Object.keys(state.edit)) {
      if (!Object.hasOwn(state.edit[k], 'value')) continue;
      if (state.edit[k].value !== null) {
        n[k] = state.edit[k].value;
      }
    }
    await api(thunk.dispatch).quote.putById(auth(thunk), id, n);
    await thunk.dispatch(fetchAll());
    await thunk.dispatch(resetEdit(id));
  }
)