
import {Fragment, h} from 'preact';
import {search} from "fast-fuzzy";
import style from './list.module.css';
import QuoteListItem from "./list-item";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectQuoteList, fetchAll, createAndOpen} from "../QuoteSlice";
import Placeholder from "../../../components/placeholder";
import {useEffect, useState} from "preact/compat";
import {LuList, LuPlus, LuRepeat} from "react-icons/lu";
import {Button, Checkbox, Input, Set, Text} from "../../../components/input";
import View from "../../../components/view";
import FoldingSurface from "../../../components/folding-surface/FoldingSurface";
import DatePicker from "react-datepicker";
import {IoOptions} from "react-icons/io5";
import {isOverrun} from "../../materials/filters/overrun";
import {route} from "preact-router";



type ItemGroup = {
	name: string|null,
	items: any
}

function setFilter(name: string, filter: string[], setLocal: (value: string[]) => any) {
	const params = new URLSearchParams(window.location.search);
	return (key: string) => {
		return (value: boolean) => {
			const next = value
				? filter.includes(key)
					? filter
					: [...filter, key]
				: filter.filter(i => i != key);
			setLocal(next);
			params.set(name, next.join(','));
			route(window.location.pathname + '?' + params.toString());
		}
	}
}

function setString(key: string, setLocal: (value: string|null) => any) {
	const params = new URLSearchParams(window.location.search);
	return (value: string|null) => {
		setLocal(value);
		if (value == null) {
			params.delete(key);
		} else {
			params.set(key, value);
		}
		route(window.location.pathname + '?' + params.toString());
	}
}

function setNumber(key: string, setLocal: (value: number|null) => any) {
	const params = new URLSearchParams(window.location.search);
	return (value: number|null) => {
		setLocal(value);
		if (value == null) {
			params.delete(key);
		} else {
			params.set(key, value.toString());
		}
		route(window.location.pathname + '?' + params.toString());
	}
}

function removeKeys(keys: string[]) {
	const params = new URLSearchParams(window.location.search);
	for (const k of keys) {
		params.delete(k);
	}
	route(window.location.pathname + '?' + params.toString());
}


const QuoteList = () => {
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectQuoteList);
	const params = new URLSearchParams(window.location.search);

	const getListOption = (key: string) => {
		const k = params.get(key) ?? "";
		return k.length > 0 ? k.split(',') : null
	}

	const getStringOption = (key: string) => {
		const k = params.get(key) ?? "";
		return k.length > 0 ? k : null
	}

	function getEnumOption<T>(key: string, enums: string[]): T | null {
		return enums.includes(params.get(key) ?? "")
			? (params.get(key) ?? "") as T
			: null;
	}

	function getIntOption(key: string, min?: number): number | null {
		const k = params.get(key);
		const i = parseInt(k ?? "", 10);
		return k != null
			? min != null
				? Math.max(i, min)
				: i
			: null
	}

	const getBooleanOption = (key: string) => {
		return params.get(key) == '1' ? true : params.get(key) == '0' ? false : null;
	}

	function setBooleanOption(key: string, local: (value: boolean) => any) {
		return (value: boolean) => {
			local(value);
			params.set(key, value ? '1' : '0');
			route(window.location.pathname + '?' + params.toString());
		}
	}

	const [searchTerm, setLocalSearchTerm] = useState<string|null>(getStringOption("search"));
	const setSearchTerm = setString('search', setLocalSearchTerm);
	const [startMin, setLocalStartMin] = useState<number|null>(getIntOption('start-min'));
	const [startMax, setLocalStartMax] = useState<number|null>(getIntOption('start-max'));
	const [filterStatus, setLocalFilterStatus] = useState<string[]>(getListOption('status') ?? ["Quote", "Order", "Hold", "Complete", "Cancelled"]);
	const [filterValid, setLocalFilterValid] = useState<string[]>(getListOption('valid') ?? ["Ok", "Overrun", "Undated"]);

	const [groupBy, setLocalGroupBy] = useState<string>(getEnumOption("group-by", ["none", "status"]) ?? "none");
	const setGroupBy = setString('group-by', v => setLocalGroupBy(v ?? "none"));
	const [sortBy, setLocalSortBy] = useState<string>(getEnumOption("sort-by", ["modified", "created", "name", "start", "client", "post-code"]) ?? "modified");
	const setSortBy = setString('sort-by', v => setLocalSortBy(v ?? "modified"));
	const [order, setLocalOrder] = useState<boolean>(getBooleanOption("order") ?? true);
	const setOrder = setBooleanOption("order", setLocalOrder);

	const setFilterStatus = setFilter('status', filterStatus, setLocalFilterStatus);
	const setFilterValid = setFilter('valid', filterValid, setLocalFilterValid);
	const setStartMin = setNumber('start-min', setLocalStartMin);
	const setStartMax = setNumber('start-max', setLocalStartMax);
	const resetFilters = () => {
		removeKeys(['start-min', 'start-max', 'valid', 'status']);
		setLocalStartMin(null);
		setLocalStartMax(null);
		setLocalFilterStatus(["Quote", "Order", "Hold", "Complete", "Cancelled"]);
		setLocalFilterValid(["Ok", "Overrun", "Undated"]);
	}
	const resetSearch = () => {
		removeKeys(['search']);
		setLocalSearchTerm(null);
	}
	const resetSorting = () => {
		removeKeys(['group-by', 'sort-by', "order"]);
		setLocalGroupBy('none');
		setLocalSortBy('modified');
		setLocalOrder(true);
	}

	useEffect(() => {
		dispatch(fetchAll());
	}, [dispatch]);

	if (state.loading) return <Placeholder>Loading</Placeholder>;

	let index: any[] = state.index;

	const preSearch = index.length;
	if (searchTerm !== null) {
		index = search(
			searchTerm, index,
			{ keySelector: (o) => `${o.client} ${o.postCode} ${o.name}`, threshold: 0.7 }
		)
	}
	const searchTotal = preSearch - index.length;


	const preFilter = index.length;
	index = index.filter(q => {
		if (startMin != null && q.start < startMin) return false;
		if (startMax != null && q.start >= startMax) return false;
		if (!filterStatus.includes(q.status)) return false;

		const valid = isOverrun(q) ? "Overrun" : q.start == null ? "Undated" : "Ok";
		if (!filterValid.includes(valid)) return false;

		return true;
	});
	const filterTotal = preFilter - index.length;

	const customSort = groupBy != "none" || sortBy != "modified" || !order;
	let sorted: ItemGroup[] = [];
	const sorter = (a: any, b: any): number => {
		const select = (x) => {
			switch(sortBy) {
				case "modified":
					return x?.modified ?? 0
				case "created":
					return x?.created ?? 0
				case "start":
					return x?.start ?? 0
				case "name":
					return x?.name ?? ""
				case "client":
					return x?.client ?? ""
				case "post-code":
					return x?.postCode ?? ""
			}
		}

		if (["modified", "created", "start"].includes(sortBy)) {
			return order ? select(b) - select(a) : select(a) - select(b);
		}

		if (["name", "client", "post-code"].includes(sortBy)) {
			return order ? select(a).localeCompare(select(b)) : -1 * select(a).localeCompare(select(b));
		}

		return 0;
	}
	switch (groupBy) {
		case "status":
			sorted =
				["Quote", "Order", "Hold", "Complete", "Cancelled"]
					.map(status => { return { name: status, items: index.filter(q => q.status === status).sort(sorter)}});
			break;
		default:
			sorted = [{
				name: null,
				items: index.sort(sorter)
			}];
			break;
	}

	return <div class={style.container}>
		<View
			ctrl={[
				[
					<Button key="refresh" label="Refresh" icon={<LuRepeat />} onClick={() => dispatch(fetchAll())} />,
					<Button key="new" label="New" icon={<LuPlus />} onClick={() => dispatch(createAndOpen())} />
				]
			]}
		>
			<div class={style.content}>
				 <FoldingSurface folds={[
					{
						icon: <IoOptions />,
						label: "Filter",
						children: (
							<Fragment>
							<Set>
								<Input label={"start after"}>
									<DatePicker
										selected={startMin}
										dateFormat="dd/MM/yyyy"
										placeholderText={"e.g. 24/08/2023"}
										onChange={(v) => setStartMin(v != null ? v.valueOf() : null)}
									/>
								</Input>
								<Input label={"start before"}>
									<DatePicker
										selected={startMax}
										dateFormat="dd/MM/yyyy"
										placeholderText={"e.g. 24/08/2023"}
										onChange={(v) => setStartMax(v != null ? v.valueOf() : null)}
									/>
								</Input>
							</Set>
							<label className={style.label}>Status</label>
							<Set>
								<Checkbox label={"Quote"} value={filterStatus.includes('Quote')} onChange={setFilterStatus('Quote')} />
								<Checkbox label={"Order"} value={filterStatus.includes('Order')} onChange={setFilterStatus('Order')} />
								<Checkbox label={"Hold"} value={filterStatus.includes('Hold')} onChange={setFilterStatus('Hold')} />
								<Checkbox label={"Complete"} value={filterStatus.includes('Complete')} onChange={setFilterStatus('Complete')} />
								<Checkbox label={"Cancelled"} value={filterStatus.includes('Cancelled')} onChange={setFilterStatus('Cancelled')} />
							</Set>
								<label className={style.label}>Validation</label>
								<Set>
									<Checkbox label={"Ok"} value={filterValid.includes('Ok')} onChange={setFilterValid('Ok')} />
									<Checkbox label={"Overrun"} value={filterValid.includes('Overrun')} onChange={setFilterValid('Overrun')} />
									<Checkbox label={"Undated"} value={filterValid.includes('Undated')} onChange={setFilterValid('Undated')} />
								</Set>
							</Fragment>
					)},
					{
						icon: <LuList />,
						label: "Sort",
						children:
							<Fragment>
								<Set>
									<Input label={"group by"}>
										<select value={groupBy} onChange={v => setGroupBy(v.currentTarget.value)}>
											<option value={"none"}>none</option>
											<option value={"status"}>Status</option>
										</select>
									</Input>
									<Input label={"sort by"}>
										<select value={sortBy} onChange={v => setSortBy(v.currentTarget.value)}>
											<option value={"modified"}>Modified</option>
											<option value={"created"}>Created</option>
											<option value={"name"}>Name</option>
											<option value={"start"}>Start</option>
											<option value={"client"}>Client</option>
											<option value={"post-code"}>Post Code</option>
										</select>
									</Input>
									<Input label={"order"}>
										<select value={order ? "ascend" : "descend"} onChange={v => setOrder(v.currentTarget.value == "ascend")}>
											<option value={"ascend"}>ascending</option>
											<option value={"descend"}>descending</option>
										</select>
									</Input>
								</Set>
							</Fragment>
					},
				]}>
					<Text value={searchTerm ?? ""} placeholder={"search"} onChange={setSearchTerm} />
				</FoldingSurface>

				{ searchTotal > 0 ?
					<div style={{ fontSize: "0.8em" }}>
						{searchTotal} item{searchTotal > 1 ? 's' : null} hidden by search <button onClick={resetSearch}>reset search</button>
					</div> : null
				}
				{ filterTotal > 0 ?
					<div style={{ fontSize: "0.8em" }}>
						{filterTotal} item{filterTotal > 1 ? 's' : null} hidden by filter <button onClick={resetFilters}>reset filter</button>
					</div> : null
				}
				{ customSort ?
					<div style={{ fontSize: "0.8em" }}>
						custom sorting applied <button onClick={resetSorting}>reset sorting</button>
					</div> : null
				}

				<div class={style.list}>
					{
						sorted.filter(g => g.items.length > 0).map((group, i) => <div key={i}>
							{ group.name != null ? <h3>{group.name}</h3> : null }
							{group.items.map(i => <QuoteListItem key={i?.id} {...i} />)}
						</div>)
					}
				</div>
				<p style={{ fontSize: "0.8em" }}>
					{state.index.length} items in total
				</p>
			</div>
		</View>
	</div>;
};


export default QuoteList;
