
export type SumType = "paint" | "total";

export default SumType;


export function typeToLabel(type: SumType) {
  switch (type) {
    case "paint":
      return "Paint";
    case "total":
      return "Material";
    default:
      return type;
  }
}