
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumPartsByLength from "../sum/partsByLength";
import {isOverrun} from "../filters/overrun";


export type BreakdownByLengthProps = {
  part: string
}

export function BreakdownByLength(props: BreakdownByLengthProps) {
  const {part} = props;

  return <Breakdown
    title={`${props.part}`}
    description={ `Overrun, Length (meters)` }
    filter={(quotes) => quotes.filter(isOverrun)}
    sum={(items) => sumPartsByLength(part, items).toString()}
  />
}

export default BreakdownByLength;
