
import {h} from "preact";
import Breakdown from "./Breakdown";
import sumThicknessByWeight from "../sum/thicknessByWeight";
import useDateRange from "../hooks/useDateRange";
import {formatDateRange} from "../utils/dateRange";
import useStatusFilter from "../hooks/useStatusFilter";
import useTabOverrun from "../hooks/useTabOverrun";
import {isOverrun} from "../filters/overrun";

export type BreakdownByThicknessProps = {
  thickness: string
}

export function BreakdownByThickness(props: BreakdownByThicknessProps) {
  const thickness = parseFloat(props.thickness);
  const [dates, [], [interval], []] = useDateRange();
  const status = useStatusFilter();
  const tabOverrun = useTabOverrun();

  return <Breakdown
    title={`${thickness}mm Sheet Material`}
    description={ formatDateRange(dates, interval) + ", Weight (tonnes)" }
    filter={(quotes) => quotes.filter(q =>
      q.start >= dates[0][0]
      && q.start < dates[dates.length - 1][1]
      && status.includes(q.status)
      && (!tabOverrun || !isOverrun(q))
    )}
    sum={(items) => sumThicknessByWeight(thickness, items).toString() }
  />
}

export default BreakdownByThickness;
