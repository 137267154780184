
import {ComponentChildren, h} from "preact";
import style from "./List.module.css";


export type ListProps = {
  children?: ComponentChildren
}

export const List = (props: ListProps) => {
  const { children } = props;

  return <div class={style.list}>
    {children}
  </div>
};

export default List;
