
import {h} from "preact";
import style from "./Summary.module.css";
import {BaseItem, withWeight} from "../../file/File";
import classNames from "classnames";
import sumPartsByQuantity from "../sum/partsByQuantity";
import {Link} from "preact-router/match";

export type PartsByQuantityProps = {
  part: string,
  interval: string,
  duration: number,
  dates: [number, number][],
  options?: string
  items: BaseItem[][],
  tabs: ({
    key: string,
    items: BaseItem[]
  } | null)[]
}


export const PartsByQuantity = (props: PartsByQuantityProps) => {
  const {part, items, interval, duration, tabs=[], dates, options=""} = props;

  return <tr>
    <th class={classNames([style.name])}>{part}</th>
    {items.map((i, k) =>
      <td key={k} class={classNames([style.number])}>
        <Link href={`/materials/quantity/${encodeURIComponent(part)}/?start=${dates[k][0]}&interval=${interval}&duration=1${options}`}>
          { sumPartsByQuantity(part, i) }
        </Link>
      </td>
    )}
    <td class={classNames([style.number])}>
      <Link href={`/materials/quantity/${encodeURIComponent(part)}/?start=${dates[0][0]}&interval=${interval}&duration=${duration}${options}`}>
        { sumPartsByQuantity(part, items.reduce((acc, cur) => [...acc, ...cur])) }
      </Link>
    </td>
    {tabs.map(k => {
        if (k == null) return null;
        return <td key={k} className={classNames([style.number, style.tab])}>
          <Link
            href={`/materials/${k.key}/quantity/${encodeURIComponent(part)}/?start=${dates[0][0]}&interval=${interval}&duration=${duration}`}>
            {sumPartsByQuantity(part, k.items)}
          </Link>
        </td>
      }
    )}
  </tr>
}
