
import {BaseItem} from "../../file/File";


export function sumPartsByQuantity(part: string, items: BaseItem[]) {
  const x = Math.round(
    items
      .filter(i => i.part === part)
      .reduce((acc, cur) => acc += cur.quantity, 0)
  );
  return x != 0 ? x.toLocaleString('en-gb') : "-";
}

export default sumPartsByQuantity;
