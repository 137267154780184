
import {File} from "../../file/File";

export function aggSalesBySum(type: "paint" | "total", items: File[]) {
  const x = Math.round(
    items
      .filter(i => i.content?.sums != undefined && Object.hasOwn(i.content.sums, type))
      .reduce((acc, cur) => {
        if (cur.content?.sums != undefined && Object.hasOwn(cur.content.sums, type)) {
          acc += cur.content.sums[type];
        }
        return acc;
      }, 0)
  );
  return x != 0 ? x.toLocaleString('en-gb') : "-";
}

export default aggSalesBySum;
