
import Plugin from "../Plugin";
import {url} from "../common";

export const AuthPlugin: Plugin = (handle) => {

  const sendCode = async(id: string) => {
    const result = await fetch(
      [url, 'auth', id].join('/'),
      {
        method: "GET"
      }
    );
    handle(result);
    if (result.ok) return Promise.resolve();
    return Promise.reject(`${result.status} ${result.statusText}`);
  }

  const verifyCode = async(id: string, code: string) => {
    const result = await fetch([url, 'auth', id].join('/'), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    });
    handle(result);
    if (result.ok) return Promise.resolve();
    return Promise.reject(`${result.status} ${result.statusText}`);
  }

  return { sendCode, verifyCode }
}
