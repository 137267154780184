
import {h} from "preact";
import {nanoid} from "@reduxjs/toolkit";
import style from "./Checkbox.module.css";


export type CheckboxProps = {
  value?: boolean,
  label?: string,
  onChange?: (v: boolean) => any,
}


export const Checkbox = (props: CheckboxProps) => {
  const {
    label = "",
    value = false,
    onChange = () => null,
  } = props;

  const name = nanoid();

  return <div class={style.group}>
    <input type="checkbox" id={name} checked={value} onChange={(e) => onChange(e.currentTarget.checked)} />
    <label htmlFor={name} class={style.label}>{label}</label>
  </div>
}

