import {useState} from "react";
import Interval from "../enums/interval";
import {getDateRanges} from "../utils/dateRange";


export function useDateRange() {
  const now = new Date(Date.now());
  const params = new URLSearchParams(window.location.search);
  const [interval, setInterval] = useState<Interval>(['month', 'quarter'].includes(params.get('interval') ?? "") ? params.get('interval') as Interval : "month");
  const [startDate, setStartDate] = useState((params.get('start') != null ? parseInt(params.get('start') ?? "") : Date.parse(`01 ${now.toLocaleString('default', { month: 'short' })} ${now.getFullYear()}`)));
  const [duration, setDuration] = useState(Math.max(1, (params.get('duration') != null ? parseInt(params.get('duration') ?? "") : 6)));

  return [
    getDateRanges(startDate, duration, interval),
    [startDate, setStartDate],
    [interval, setInterval],
    [duration, setDuration]
  ] as const;
}

export default useDateRange;